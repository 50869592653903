<template lang="">
    <span>
        <!-- <span v-if="editando">
            <v-text-field ref="select_editable" v-model="nuevo_valor"
                @keyup.enter="modificar"
                @blur="modificar"
                @keyup.esc="cancelar"
            />
        </span> -->
        <span v-if="editando">
            <!-- <v-row>
                <v-col> -->
                    <v-select ref="select_editable" :items=options v-model="nuevo_valor" @blur="modificar"></v-select>
                <!-- </v-col>
            </v-row> -->
        </span>
        <span class="pointer" @dblclick="hacerEditable" v-else>{{valor}}</span>
    </span>
</template>
<script>
import Vue from "vue";
export default {
    data() {
        return {
            nuevo_valor:null,
            editando:false
        }
    },
    watch:{
        valor(){
            this.nuevo_valor=this.valor;
        }
    },
    methods: {
        hacerEditable(){
            this.editando=true;
            Vue.nextTick(() => {
                this.$refs['select_editable'].focus();
            });
        },
        modificar(){
            this.$emit('cambioTexto', {oldValue:this.valor, newValue:this.nuevo_valor, prop:this.propiedad})
            this.editando=false;
        },
        cancelar(){
            this.nuevo_valor=this.valor;
            this.editando=false;
        }
    },

    created() {
        this.nuevo_valor=this.valor;
    },

   props:{
    valor:{
        type:[String,Number]
    },
    propiedad:{
        type:String
    },
    options: Array,
   }
    
}
</script>
<style>
    .pointer {cursor: pointer;}

</style>