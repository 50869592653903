import KoioteImageSwitchState from './KoioteImageSwitchState.vue'
import KoioteImageSwitchStateProperties from './KoioteImageSwitchStateProperties.vue'
import KoioteImageSwitchStateProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component('koiote-image-switch-state', KoioteImageSwitchState);
        Vue.component('koiote-image-switch-state-properties', KoioteImageSwitchStateProperties);
        Vue.prototype.$koioteImageSwitchStateProperty = KoioteImageSwitchStateProperty;

        Vue.mixin({
            mounted() {
             // console.log('Montadito!');
            }
          });
       }
  };
  
