<template>
  <div>
    <main-component  />
    <v-dialog v-model="dialog"  :overlay="false" max-width="500px"
                    transition="dialog-transition">
      <v-card>
        <v-card-title class="text-h5">
            Cambios sin guardar
            <v-spacer></v-spacer>
            <v-icon
                slot="prepend"
                color="warning"
                >
                mdi-alert-outline
            </v-icon>
        </v-card-title>
        <v-card-text>Hay cambios sin guardar en la página, si la abandona se perderán. ¿Está seguro?</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">
            Cancelar
            </v-btn>
            <v-btn text color="error" @click="dialog = false; dontSave();">
            Salir sin guardar
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
import MainComponent from '../components/MainComponent'

  export default {
    name: 'Home',
    data() {
      return {
        dialog: false,
        leaveWithoutSaving: false,
        path: ""
      };
    },
    components: {
      MainComponent,
    },
    computed:{
      ...mapState(['modified_draggables','modified_pages']),
     
    },
    methods:{
      ...mapActions(['deleteModifiedDraggables','deleteModifiedPages']),
      async dontSave(){
        this.deleteModifiedDraggables()
        this.deleteModifiedPages()
        this.$router.push(this.path)
      },
      beforeUnload (e) {
        if(this.modified_draggables.length > 0 || this.modified_pages.length > 0){
          e.preventDefault()
          e.returnValue = ''
        }
        // if(this.modified_draggables.length > 0 || this.modified_pages.length > 0){
        //   this.dialog = true
        // }
      // if(this.modified_draggables.length > 0 || this.modified_pages.length > 0){
      //   this.path = to.path
      //   this.dialog = true
      //   next(false)
      // }
      // else{
      //   next()
      // }
    },
    unload(){
      this.deleteModifiedDraggables()
      this.deleteModifiedPages()
    }
  },
  created(){
    //  window.onbeforeunload = function(){}
    window.addEventListener('beforeunload', this.beforeUnload)
    window.addEventListener('onunload', this.unload)
  },
  beforeRouteLeave (to, from, next) {
    if(this.modified_draggables.length > 0 || this.modified_pages.length > 0){
      this.path = to.path
      this.dialog = true
      next(false);
    }else{
      next()
    }
  },
        
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnload)
    window.removeEventListener('onunload', this.unload)

  }
}
</script>
