<template>
  <v-app-bar id="v-app-1" v-if="isNotLogin()" app color="white">
    <!-- <div class="d-flex align-center"> -->
      <!-- <router-link to="/" > -->
        <img @click="tab = 'one'; $route.name != 'Home'?$router.push({path:'/'}):null" alt="Vuetify Logo" class="shrink" contain src="@/assets/ramos-solar-icono.png"
          transition="scale-transition" :width="$vuetify.breakpoint.smAndDown?'8%':'4%'" style="cursor:pointer"/>
        <img v-if="!$vuetify.breakpoint.smAndDown" @click="tab = 'one'; $route.name != 'Home'?$router.push({path:'/'}):null" alt="Vuetify Logo" class="shrink mr-2" contain src="@/assets/ramos-solar-letras.png"
          transition="scale-transition" width="7%" style="cursor:pointer"/>
          

      <!-- </router-link> -->
    <!-- </div> -->
    <div v-if="getIsAdmin && $route.name == 'Home'">
      <v-btn v-show="!getIsPlayMode" fab ref="play" small class="elevation-4"
        @click="hide_menus"><v-icon>mdi-play</v-icon> </v-btn>
      <v-btn v-show="getIsPlayMode" @click="show_menus" ref="stop" fab small
        class="ml-3 elevation-4"><v-icon>mdi-stop</v-icon> </v-btn>

        <!-- guardar todos los cambios en la bd -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-show="!getIsPlayMode"  v-on="on" text :disabled="modified_draggables.length == 0 && modified_pages.length == 0"
              @click="saveDataInDB"><v-icon>mdi-content-save</v-icon> </v-btn>
          </template>
          <span>Guardar (ctrl+s)</span>
        </v-tooltip>


        <!-- deshacer el ultimo cambio -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-show="!getIsPlayMode" x-small v-on="on"  text :disabled="changes.length == 0"
              @click="undo"><v-icon>mdi-undo</v-icon> </v-btn>
          </template>
          <span>Deshacer (ctrl+z)</span>
        </v-tooltip>

        <!-- rehacer el ultimo cambio -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-show="!getIsPlayMode" v-on="on" x-small text :disabled="revert_changes.length == 0"
              @click="redo"><v-icon>mdi-redo</v-icon> </v-btn>
          </template>
          <span>Rehacer (ctrl+y)</span>
        </v-tooltip>
    </div>
    <v-col :cols="$vuetify.breakpoint.smAndDown?6:3">
      <v-select v-if="plantas_usuario.length > 1"
      hide-details
      v-model="planta"
      @change="setSelectedPlanta"
      prefix="Planta: "
      :items="plantas_usuario"
      item-text='name'
      return-object
      ></v-select>
      <span v-else-if="$vuetify.breakpoint.smAndDown && plantas_usuario.length == 1">Planta de {{plantas_usuario[0].name}}</span>
      <v-card-title v-else-if="plantas_usuario.length == 1">Planta de {{plantas_usuario[0].name}}</v-card-title>
    </v-col>
    <div>
    </div>
    <v-spacer></v-spacer>

      <v-col v-if="getIsPlayMode && !$vuetify.breakpoint.smAndDown" cols="3" class="mr-3 pb-0" >
        <!-- Para pantallas grandes -->
        <v-select hide-details prefix="Reproducción: " v-model="modo" 
        @change="setModoDeReproduccion(modo)" class="pb-0" :items="selector_modo">
      </v-select>
    </v-col>

        <!-- Para pantallas pequenas -->
      <v-menu v-else-if="getIsPlayMode" >
        <template v-slot:activator="{ on }">
          <v-icon class="mr-5" v-on="on">mdi-dots-vertical</v-icon>
        </template>
        <v-list class="pt-0 pb-0">
          <v-list-item
            @click="setModoDeReproduccion('Automática')" 
            :style="
              modo_reproduccion == 'Automática' ? 'background-color:rgba(231, 231, 231, 0.6)' : null
            "
            class="justify-center"
            >Reproducción Automática</v-list-item
            >
          <v-list-item
          @click="setModoDeReproduccion('Manual')" 
            :style="
              modo_reproduccion == 'Manual' ? 'background-color:rgba(231, 231, 231, 0.6)' : null
            "
            >Reproducción Manual</v-list-item
          >
        </v-list>
      </v-menu>

    <div>
      <!-- <v-spacer></v-spacer> -->
      <!-- Cerrar Sesion -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mt-1 elevation-1" v-on="on" @click="logoutFromApp" rounded> <v-icon >mdi-logout</v-icon></v-btn>
        </template>
        <span>Cerrar Sesión</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";


export default {
  name: "NavigationBar",
  data() {
    return {
      selectedIndex: null,
      tab: null,
      modo:'',
      planta:{},
      selector_modo:["Automática","Manual"]
    };
  },
  computed: {
    ...mapState('solar',['modo_reproduccion','plantas','selected_planta']),
    ...mapState(['modified_draggables', 'modified_pages','changes','revert_changes']),
    ...mapGetters(["getPages", "getSelectedPage", "getLastSelectedPage", "getIsPlayMode", 'getIsAdmin', 'getMainPage','getRol']),
    plantas_usuario(){
      const user = JSON.parse(localStorage.getItem('user'))
      if(!user.plantas){
        console.log("ERROR: No hay plantas asignadas al usuario")
        this.logout()      
      }
      let plantas = user.plantas
      return this.plantas.filter(p => plantas.includes(p.id_planta));
    },
    pagina_principal() {
      return this.getSelectedPage.is_main_page
    },

  },
  mounted(){
    this.planta = this.selected_planta
  },
  methods: {
    ...mapActions(["setSelectedPage", "setIsPlayMode", "unselectPage", 'mousemove', 'deleteComponentSelected',
      'deleteModifiedDraggables', 'deleteModifiedPages', 'goToBackPage','undo','redo','saveDataInDB']),
    ...mapActions('api', ['putDraggable', 'putDraggableProperties', 'putPageName', 'logout','login']),
    ...mapActions('solar',['setModoDeReproduccion','setSelectedPlanta']),

    isRolNormal(){
      return this.getRol != 'admin' && this.getRol != 'avanzado'
    },
    isNotLogin() {
      return this.$route.name != "Login";
    },
    logoutFromApp() {
      document.removeEventListener("mousemove", this.mousemove()) // si no, al cambiar de ruta daba errores porque no encontraba la screen
      this.logout()
    },
    hide_menus() {
      // deseleccionamos si hay algun componente seleccionado para que al volver a la edicion no de problemas
      this.deleteComponentSelected()

      // seleccionamos la pagina principal para ejecutar el scada
      if (this.getPages.length > 0) {
        let mainPage = this.getPages.find(e => e.is_main_page == true)
        if (mainPage)
          this.setSelectedPage(mainPage)
        else
          this.setSelectedPage(this.getPages[0])
          // this.setSelectedPage(this.getPages[3])

      }
      this.setIsPlayMode(true)
      //this.$emit('show',false)      
    },
    show_menus() {
      // this.$emit('show',true) 
      this.setIsPlayMode(false)

    },
    home() {
      this.tab = null
      this.$route.name != 'Home' ? this.$router.push({ name: 'Home' }).catch(() => { }) : null
      if (this.getPages.length > 0) {
        let mainPage = this.getPages.find((e) => e.is_main_page == true);
        if (mainPage) this.setSelectedPage(mainPage);
        else this.setSelectedPage(this.getPages[0]);
      }

    }
  },
  watch: {
    $route() {
      if (this.$route.name == 'alarms' && this.tab != 0)
        this.tab = 0
      // else if (this.$route.name == 'horno' && this.tab != 1)
      //   this.tab = 1
      else if (this.$route.name == 'users' && this.tab != 1)
        this.tab = 1
    },
    modo_reproduccion(){
      this.modo = this.modo_reproduccion
    },
    selected_planta(){
      this.planta = this.selected_planta
    }

  }
};
</script>
<style>
.pantalla_pequena {
  z-index: 300;
}
</style>





