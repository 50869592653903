import axios from 'axios';
import router from '@/router'

const state = () => ({
  myAxios: axios.create({
    baseURL: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_BACK_HOST}:${process.env.VUE_APP_PORT}`,
    headers: {
      "Content-Type": "application/json",
      common: {
        "Authorization": 'Bearer ' + localStorage.getItem('token') || null
      }
    },
  }),
  user: {},
})
const getters = {}
const mutations = {}

const actions = {
    setUpInterceptors({state}){
    state.myAxios.interceptors.request.use(function(config) {
      let token = localStorage.getItem('token')
      if(token) {
        config.headers.Authorization = `Bearer ${token}`;
      }else{
        if(router.currentRoute.name != 'Login')
          this.dispatch('api/logout')
      }
      return config;
    }, function(err) {
        return Promise.reject(err);
    });
    state.myAxios.interceptors.response.use((response) => {
        // If the response status is between 200 and 299, it's a successful response, so return it as is.
        return response;
      },(error) => {
        if (error.response && error.response.status === 413) {
        //console.error('Payload Too Large (413) - The server refused to process the request due to large payload.');
        }else if(error.response && error.response.status === 401){
          if(router.currentRoute.name != 'Login')
            this.dispatch('api/logout')
        }else if(error.response && error.response.status === 400){
          console.warn("HA HABIDO UN ERROR EN AXIOS: ",error.response.data.msg?error.response.data.msg:error.response);
        }
        // return Promise.reject(error);
      }
    );
  },
  setUser({ state }, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  },
  setToken({ state }, token) {
    localStorage.setItem('token', token)
  },

  //    LOGIN & LOGOUT
  async login({ state }, userInfo) {
    try {
      const res = await state.myAxios.post('api/auth/login/', userInfo)
      if (res.data) {
        this.dispatch('api/setToken', res.data.token)
        let user = { user_id: res.data.user_id, username: res.data.username, role_name: res.data.role_name, plantas: res.data.plantas }
        this.dispatch('api/setUser', user)
       
        if (res.data.role_name == 'admin') {
          this.dispatch('setRol', 1)
          this.dispatch('setIsPlayMode', false)
          this.dispatch('setIsAdmin', true)
        } else {
          if (res.data.role_name == 'avanzado') this.dispatch('setRol', 2)
          else this.dispatch('setRol', 3)
          this.dispatch('setIsPlayMode', true)
          this.dispatch('setIsAdmin', false)
        }
      } else {
        console.log("Problema al loggearse");
      }
    }
    catch (error) {
      return error;
    }
  },
  logout({ state }) {
      this.dispatch('solar/pararReproduccionAutomatica')
      state.user = {}
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      router.push({path:'\login'}).catch(failure => {})
  },

  //    PAGES
  getPages({ state }) {
    return state.myAxios.get(`api/page/`)
  },
  putPage({state}, page){
    return state.myAxios.put(`api/page/${page.id}`, page)
  },
  deletePageLandscapeImage({ state }, id) {
    const formData = new FormData();
    formData.append("file", {}); 
    return state.myAxios.put(`api/page/image/${id}`, formData)
  },
  deletePagePortraitImage({ state }, id) {
    const formData = new FormData();
    formData.append("file", {}); 
    return state.myAxios.put(`api/page/image/portrait/${id}`, formData)
  },
  putPageName({ state }, page) {
    return state.myAxios.put(`api/page/${page.id}`, page)
  },
  deletePage({ state }, id) {
    return state.myAxios.put(`api/page/${id}`, { delete: true})
  },
  postPage({ state }, page) {
    console.log(page);
    return state.myAxios.post(`api/page/`, page)
  },

  //    DRAGGABLES
  postDraggable({ state }, draggable) {
    return state.myAxios.post('api/draggable/', draggable)
  },
  deleteDraggable({ state }, id) {
    return state.myAxios.put(`api/draggable/${id}`,{ delete: true })
  },
  putDraggable({ state }, payload) {
    return state.myAxios.put(`api/draggable/${payload.id}`, payload);
  },
  getDraggables({ state }) {
    return state.myAxios.get('api/draggable/')
  },
  getPageDraggables({ state }, id) {
    return state.myAxios.get(`api/draggable/page/${id}`)
  },


  //    USERS
  getUsers({ state }) {
    return state.myAxios.get('api/user/')
  },
  getUser({ state }, user_id) {
    return state.myAxios.get(`api/user/${user_id}`)
  },
  postUser({ state }, user) {
    return state.myAxios.post('api/user/', user)
  },
  inactivateUser({ state }, user_id) {
    return state.myAxios.put(`api/user/${user_id}`, { delete: true})
  },
  updateUser({ state }, user) {
    return state.myAxios.put(`api/user/${user.user_id}`, user)
  },
  updateUserInformation({ state }, user) {
    return state.myAxios.put(`api/user/${user.user_id}`, user)
  },
  changeUserPassword({ state }, user) {
    return state.myAxios.put(`api/user/password/${user.user_id}`, user)
  },
  
  //    PLANTAS
  getPlantas({ state }) {
    return state.myAxios.get('api/planta/')
  },


  //    GET LAST DATA
  getSolaredgeData({ state }) {
    return state.myAxios.get('api/last_data/')
  },



}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}