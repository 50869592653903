<template>
    <div  class="canvas_completo">
      <grafica-horizontal
      :states="estados_data"
      :ejeX="[]" :datos="porcentajes_estados" 
      :totalPuntos="100"></grafica-horizontal> 
    </div>
  </template>
  <script>
import { mapState } from 'vuex';
  import GraficaHorizontal from './GraficaHorizontal.vue';
  
  export default {
    name: 'KoioteGraficoHorizontal',
    props:{
      myProps:Object
    },
    components: {
      GraficaHorizontal
    },
  
    computed: {
      ...mapState('api', ['energia', 'energiaHoy', 'energiaMes', 'energiaSemana']),
      anchoBarraDatos(){
        return document.querySelector('#barra_datos')
      },
      estados_data(){
        return [
          {valor: this.myProps.customizables.label_state_property1, color: this.myProps.customizables.color_state_property1},
          {valor: this.myProps.customizables.label_state_property2, color: this.myProps.customizables.color_state_property2}
        ]
      },
      porcentajes_estados(){
        const nestedProperties1 = this.myProps.customizables.state_property1.split('.');
        // const nestedProperties2 = this.myProps.customizables.state_property2.split('.');
        const nestedValue1 = nestedProperties1.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, this.$store.state.solar);
        // const nestedValue2 = nestedProperties2.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, this.$store.state.api);
        let valorInicio = parseFloat(nestedValue1)
        // let valorFin = parseFloat(nestedValue2)


        return [
          { inicio:0, fin:valorInicio, valor: this.myProps.customizables.label_state_property1},
          { inicio:valorInicio, fin:100, valor: this.myProps.customizables.label_state_property2}
        ]
      },

    },
    data() {
      return {
        // estados_data:[
        // // { valor: 10,  color: "#FF0000"},
        // {valor: "Casa", color: "#00ff00"}, // verde, a casa
        // {valor: "Red", color: "#00d3ff"}, // azul, a red
        // ],
        eje_x_datos:[],
  
        datos: [
        //   { valor: 10, fechaInicio: new Date("2023-10-26T00:01:10"), fechaFin: new Date("2023-10-26T08:30:00") },
          { valor: 20, fechaInicio: new Date("2023-10-26T11:35:05"), fechaFin: new Date("2023-10-26T16:30:00") },
          { valor: 20, fechaInicio: new Date("2023-10-26T11:35:05"), fechaFin: new Date("2023-10-26T16:30:00") },
          { valor: 20, fechaInicio: new Date("2023-10-26T11:35:05"), fechaFin: new Date("2023-10-26T16:30:00") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          { valor: 20, fechaInicio: new Date("2023-10-26T18:32:15"), fechaFin: new Date("2023-10-26T19:50:30") },
          
          { valor: 30, fechaInicio: new Date("2023-10-26T16:35:45"), fechaFin: new Date("2023-10-26T18:30:30") },
          { valor: 30, fechaInicio: new Date("2023-10-26T19:52:15"), fechaFin: new Date("2023-10-26T23:25:30") },
          { valor: 30, fechaInicio: new Date("2023-10-26T19:52:15"), fechaFin: new Date("2023-10-26T23:25:30") },
          { valor: 30, fechaInicio: new Date("2023-10-26T19:52:15"), fechaFin: new Date("2023-10-26T23:25:30") },
          { valor: 30, fechaInicio: new Date("2023-10-26T19:52:15"), fechaFin: new Date("2023-10-26T23:25:30") },
          { valor: 30, fechaInicio: new Date("2023-10-26T19:52:15"), fechaFin: new Date("2023-10-26T23:25:30") },
        //   { valor: 10, fechaInicio: new Date("2023-10-26T23:26:25"), fechaFin: new Date("2023-10-26T23:59:59") }
        ],
  
  
        estados_por_fechas: [],
        // porcentajes_estados:[],
        canvas_eje_x:null
      }
  
    },
  
    methods: {
      setEstadosPorFechas(){
        this.estados_por_fechas=this.prototipoFechas.converir_fechas_en_posiciones(this.datos,'fechaInicio','fechaFin')
      },
      setEstadosPorcentajes(){
         this.porcentajes_estados = [
          {fin:this.energiaMes.porcentaje_producido_casa, inicio:0, valor:20},
          {fin:100, inicio:this.energiaMes.porcentaje_producido_casa, valor:30}
        ]
        console.log("LA DIFERENCIA: ",this.porcentajes_estados);
      },
      resize(payload){
        this.canvas_eje_x=payload;
        this.recalcularEjes();
      },
      recalcularEjes(){
        // this.eje_x_datos= this.prototipoFechas.createArrayOfMoments(this.canvas_eje_x, this.datos[0].fechaInicio,this.datos[this.datos.length-1].fechaInicio,1,'hours',"HH:mm:ss",true)
        this.eje_x_datos= this.prototipoFechas.createArrayOfMoments(this.canvas_eje_x, 0,this.porcentajes_estados.fin,1,'%',"HH:mm:ss",true)
        this.eje_x_datos.splice();
        console.log("EJEX: ",this.eje_x_datos);
      }
     
    }
  }
  </script>
  <style>
  .columna_barra{
    position: absolute;
  }
  .canvas_completo{
    width:100%;
    height: 100%;
  }
  </style>
  
  