<template lang="">
  <div>
    <v-select
    class="ml-3 mr-3"
    prefix="Escala"
    :items="scales"
    item-text="name"
    @change="addModifiedDraggable"
    v-model="component_selected.component_props.customizables.scale"
  ></v-select>
  <v-select
    class="ml-3 mr-3"
    prefix="Número de Decimales"
    @change="addModifiedDraggable"
    :items="decimals"
    v-model="component_selected.component_props.customizables.decimals"
  ></v-select>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      decimals: [0,1,2,3,4],
      scales: [{name:"1:1", value: 1},
                {name:"1:10", value: 0.1},
                {name:"1:100", value: 0.01},
                {name:"1:1000", value: 0.001},
                {name:"1:0.1", value: 10},
                {name:"1:0.01", value: 100},
                {name:"1:0.001", value: 1000},
    
      ],
    };
  },
  methods: {
    ...mapActions(['addModifiedDraggable'])
  },
  computed:{
    ...mapState(['component_selected'])
  }
}
</script>
<style lang="">
  
</style>