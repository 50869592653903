<template lang="es">
    <!-- este es un label -->

    <div :style="myStyle" class="label">
        <!-- {{myProps}} -->
        {{myProps.customizables.text}}

    </div>
    <!-- Fin del label -->
</template>
<script>
import { mapState } from 'vuex';

export default {
    name:"KoioteLabel",
    props:{
        myProps:Object,
        w:Number,
        h:Number
    },
    computed: {
        ...mapState(['component_selected']),
        ancho(){
            let minimo = Math.min(this.h, this.w)
            if(document.documentElement.clientWidth >= 700)
                return minimo/2.5
            else
                return minimo/4
        },
        myStyle()
        {
            let style = {
                backgroundColor:this.myProps.customizables.background_color, 
                color:this.myProps.customizables.foreground_color,
                //   fontSize:this.ancho+'px,'
                width: "100%",
                height: "100%",
                position: "absolute",
                alignContent: "center",
                textAlign: "center",
            }
            if(this.myProps.customizables.border){
                style.border = `${this.myProps.customizables.border_width}px solid ${this.myProps.customizables.border_color}`
            }

            return style
           
        }
    },
   

}
</script>
<style>

.label{
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-size: 1.5vw;
  font-family: 'Cairo', sans-serif!important;
  /* font-family: 'Tajawal', sans-serif!important; */
  /* line-height: 0.2; */
}

@media (orientation: portrait) {
  .label {
    font-size: 1.5vw;
  }
}

@media (orientation: landscape) and (max-width: 600px) {
  .label {
    font-size: 0.85vw;
  }
}

@media (orientation: portrait) and (max-width: 500px) {
  .label {
    font-size: 2.5vw;
  }
}


    
</style>