<template lang="es">
   <div class="video-player">
    <v-overlay :value="isLoading" absolute>
        <v-progress-circular indeterminate size="100">Cargando...</v-progress-circular>
      </v-overlay>
    <video ref="videoPlayer" :src="src" controls :muted="!myProps.customizables.sound" @ended="onVideoEnded">
      Your browser does not support the video tag.
    </video>
    <!-- {{isVisible}} -->
  </div>

</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: "KoioteVideoPlayer",
  props: {
    myProps: Object,
    isVisible:Boolean
  },

  data() {
    return {
      isLoading: false,
      src:null,
      siguiente_pagina:null,
    }
  },

  async created(){
    if(this.myProps.customizables.video_src){
      this.isLoading = true
      const response = await fetch(`/files/${this.myProps.customizables.video_src}`)
      // console.log(response);
      const blob = await response.blob();
      this.src = URL.createObjectURL(blob);
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions('solar',['setReproduciendoVideo']),
    ...mapActions(['setSelectedPage']),

    playVideo() {
      const video = this.$refs.videoPlayer
      if (video) {
        this.setReproduciendoVideo(true)
        video.play();
      }
    },
    pauseVideo() {
      const video = this.$refs.videoPlayer
      if (video) {
        this.setReproduciendoVideo(false)
        video.pause();
        video.currentTime = 0; //  para que empiece desde 0 la proxima vez que se reproduzca
      }
    },
    onVideoEnded() {
      const pagina = this.getPages.find(e =>e.id == this.siguiente_pagina)
      this.setSelectedPage(pagina)
     this.pauseVideo()
    },
   
  },
  computed:{
    ...mapGetters(['getSelectedPage','getIsPlayMode','getPages']),
    ...mapState('solar', ['reproduciendo_video'])

  },
  watch: {
    'myProps.customizables.video_src':async function(){
      if(this.myProps.customizables.video_src){
        this.isLoading = true
        const response = await fetch(`/files/${this.myProps.customizables.video_src}`);
        const blob = await response.blob();
        this.src = URL.createObjectURL(blob);
        this.isLoading = false
      }else{
        this.src = null
      }
    },
    getSelectedPage(){
      if(this.myProps.customizables.autoplay && this.getIsPlayMode){
        if(this.isVisible  && !this.reproduciendo_video){
          this.siguiente_pagina = this.getSelectedPage.next_page_id
          this.playVideo()
        }else if(this.reproduciendo_video){// si manualmente se cambia de pagina
          this.pauseVideo()
        }
      }
    }

  }
};
</script>
<style>
.video-player {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

video {
  width: 100%!important;
  height: 100%!important;
}
</style>