import axios from 'axios';
import router from '@/router'

const state = () => ({
  potenciaActual:{Placas:0,Red_electrica:0, Consumido:0, Estado:""} ,
  estados_potencia:{placas:0, casa:0,red:0},
  potenciaHoy:{},
  energia:{},
  energiaHoy:{
    producido_casa:0,
    producido_sobrante:0,
    consumido_casa:0,
    consumido_red:0,
    totalConsumido:0, 
    totalProducido:0, 
    porcentaje_consumido_casa:0, 
    porcentaje_consumido_red:0, 
    porcentaje_producido_casa:0, 
    porcentaje_producido_sobrante:0
  },
  energiaSemana:{
    producido_casa:0,
    producido_sobrante:0,
    consumido_casa:0,
    consumido_red:0,
    totalConsumido:0, 
    totalProducido:0, 
    porcentaje_consumido_casa:0, 
    porcentaje_consumido_red:0, 
    porcentaje_producido_casa:0, 
    porcentaje_producido_sobrante:0
  },
  energiaMes:{
    producido_casa:0,
    producido_sobrante:0,
    consumido_casa:0,
    consumido_red:0,
    totalConsumido:0, 
    totalProducido:0, 
    porcentaje_consumido_casa:0, 
    porcentaje_consumido_red:0, 
    porcentaje_producido_casa:0, 
    porcentaje_producido_sobrante:0
  },
  prueba:{ai:10, bi:300},
  total_energy:{},
  medioambiente:{lightBulbs:0,treesPlanted:0,gasEmissionSaved: {co2:0}},
  planoPlanta:{},
  ultima_lectura:null,
  plantas:[],
  selected_planta:{},
  modo_reproduccion:null,
  reproduciendo_video:false,
  intervalId:null,
  datos_solaredge:[]
})
const getters = {

}
const mutations = {

}
const actions = {
    
  //    PLANTAS
  setPlantas({dispatch, state},plantas){
    state.plantas = plantas
    let usuario = JSON.parse(localStorage.getItem('user'))
    if(usuario.plantas.length > 0){
      let primera_planta = state.plantas.find(e => e.id_planta == usuario.plantas[0])
      dispatch('setSelectedPlanta', primera_planta)
    }
},
  setSelectedPlanta({state,dispatch}, planta){
    state.selected_planta = planta
    if(state.datos_solaredge.length > 0){
      let datos_planta = state.datos_solaredge.find(e => e.id_planta == state.selected_planta.id_planta)
      if(datos_planta){
        dispatch('tratarDatosSolaredge', datos_planta.datos)
      }
    }
  },
  setDatosSolaredge({dispatch, state}, datos){
    state.datos_solaredge = datos
    if(Object.keys(state.selected_planta).length > 0){
      let datos_planta = state.datos_solaredge.find(e => e.id_planta == state.selected_planta.id_planta)
      if(datos_planta){
        dispatch('tratarDatosSolaredge', datos_planta.datos)
      }
    }
  },

  //  API SOLAREDGE
  tratarDatosSolaredge({state}, datos){
    try{
      state.potenciaActual = datos.potenciaActual
      if(datos.potenciaActual.Estado){
        if(datos.potenciaActual.Estado == 'Active' && parseFloat(datos.potenciaActual.Placas) > 0)
          state.estados_potencia.placas = 1 // las placas estan funcionando
        else
        state.estados_potencia.placas = 0 // las placas no estan funcionando
      }
      if(datos.potenciaActual.Consumido){
        if(parseFloat(datos.potenciaActual.Consumido) > 0){// si esta consumiendo energia
          if(parseFloat(datos.potenciaActual.Placas)>0 && parseFloat(datos.potenciaActual.Red_electrica)>0 && parseFloat(datos.potenciaActual.Placas) < parseFloat(datos.potenciaActual.Consumido))//si es mezcla de las dos
            state.estados_potencia.casa = 2
          else if(parseFloat(datos.potenciaActual.Placas)>0)// si solo es de las placas
            state.estados_potencia.casa = 1
          else if(parseFloat(datos.potenciaActual.Red_electrica)>0)// si solo es de las placas
            state.estados_potencia.casa = 3
          }
        else
        state.estados_potencia.casa = 0 // no se esta consumiendo energia
      }  
      if(datos.potenciaActual.Red_electrica ){
        if(parseFloat(datos.potenciaActual.Placas) - parseFloat(datos.potenciaActual.Consumido) >= 0 && parseFloat(datos.potenciaActual.Red_electrica) > 0)
          state.estados_potencia.red = 2 // hay sobrante y va a la red
        else if(parseFloat(datos.potenciaActual.Placas) - parseFloat(datos.potenciaActual.Consumido) < 0 && parseFloat(datos.potenciaActual.Red_electrica) > 0)
          state.estados_potencia.red = 1 // se esta consumiendo de la red
        else
          state.estados_potencia.red = 0 // las placas no estan funcionando
      } 

      state.potenciaHoy = datos.potenciaHoy
      state.medioambiente = datos.medioambiente
      state.energia = datos.energia
      state.energiaHoy = datos.energiaHoy
      state.energiaSemana = datos.energiaSemana
      state.energiaMes = datos.energiaMes
      state.energiaTotal = datos.energiaTotal
      state.ultima_lectura = datos.fecha_lectura

    }catch(e){
      console.log("Error leyendo los datos del json ",e);
    }
  },
  iniciarReproduccionAutomatica({commit, state}){
    state.intervalId = setInterval(() => {this.dispatch('goToNextPage');}, process.env.VUE_APP_TIEMPO_REPRODUCCION);
  },
  pararReproduccionAutomatica({state}){
    clearInterval(state.intervalId)
    state.intervalId = null
  }, 
  setModoDeReproduccion({dispatch, commit, state}, modo){
    state.modo_reproduccion = modo
    if(state.modo_reproduccion == 'Automática'){
      dispatch('iniciarReproduccionAutomatica')
    }else{
      dispatch('pararReproduccionAutomatica')
    }
  },
  setReproduciendoVideo({dispatch, state}, playing){
    if(state.modo_reproduccion == 'Automática'){
      if(playing){
        dispatch('pararReproduccionAutomatica')
      }else{
        dispatch('pararReproduccionAutomatica')
        dispatch('iniciarReproduccionAutomatica')
      }
    }
    state.reproduciendo_video = playing
  },



}
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}