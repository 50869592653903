<template lang="es">
  <v-card text>
    <v-list>
      <v-list-item-group>
        <v-checkbox
          class="ml-2 mb-2"
          label="Reproducir automáticamente"
          color="info"
          v-model="component_selected.component_props.customizables.autoplay"
          hide-details
          ></v-checkbox>

          <v-checkbox
          class="ml-2 mb-2"
          label="Reproducir con sonido"
          color="info"
          v-model="component_selected.component_props.customizables.sound"
          hide-details
          ></v-checkbox>
      <!-- Video a reproduccir-->
      <v-list-item>
        <v-list-item-content>
          <v-row v-show="component_selected.component_props.customizables.video_src != null">
            <v-col>
              Video:
            </v-col>
            <v-col>
             <span>{{component_selected.component_props.customizables.video_src}}</span>
            </v-col>
            <v-col>
              <v-icon  class="mt-1" @click="borrarVideo">
                mdi-close
              </v-icon>
            </v-col>
          </v-row>
            <v-row v-show="component_selected.component_props.customizables.video_src == null">
              <v-col cols="4">
              Video:
            </v-col>
            <v-col cols="8">
              <v-file-input
              accept="video/*"
              label="Seleccionar"
              dense
              v-model="video"
              background-color="white"
              ref="ref_file_input_without_plc"
              @change="onFileChange"
              ></v-file-input> 
            </v-col>
            </v-row>

        </v-list-item-content>
      </v-list-item>


      </v-list-item-group>
  </v-list>
</v-card>

</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "KoioteVideoPlayerProperties",
  computed: {
    ...mapState(["component_selected"])
  },
  methods: {
    ...mapActions(["updateComponent",'changeEditing']),
    onFileChange(file) {
      if (file) {
        this.component_selected.component_props.customizables.video_src = file.name
        this.video = file
      } else {
        this.component_selected.component_props.customizables.video_src = null
        this.video = null;
      }
    },
    borrarVideo(){
      this.component_selected.component_props.customizables.video_src= null
      this.video = null
    },
   
    

  },
  data() {
    return {
      video: null,
    };
  },
};
</script>
<style >
.imagen_pequeña{
  position:absolute;
  width:100%;
  height: 100%;
}
</style>