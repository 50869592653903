import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Página principal',
          content: 'Página principal del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta:{
      title: process.env.VUE_APP_TITLE,
      metaTags: [
        {
          name: 'Login',
          content: 'Página de Login del editor de Scada para ' + process.env.VUE_APP_COMPANY
        },
      ]
    }
   // meta: { hideNavbar: true},
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('token')
  
  const pageTitle = to.meta.title
  if (pageTitle) 
    document.title = `${pageTitle}`
  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    console.log('not logged in')
    next({name:'Login'})
  } else {
    if (loggedIn ? to.path !== '/login' : true) next();
  }
})

export default router
