<template lang="">
    <v-container class="rounded">
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Página </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    ID: {{getSelectedPage.id}}
    <v-divider class="mb-4"></v-divider>
        <v-text-field
            :label="getSelectedPage.name"
            placeholder="Nuevo Nombre"
            v-model="new_title"
        /> 

        <!-- IMAGEN DE FONDO EN HORIZONTAL -->
        <v-divider></v-divider>
       <p class="mt-5 ml-2" :style="'color:#6B6B6B'"> Fondo Horizontal:</p>
      <v-file-input v-show="!getSelectedPage.image || getSelectedPage.image == null"
          accept="image/*" 
          label="Seleccionar..."
          @change="cambiarFondo($event)"
          ref="ref_pag_image_file_input"
      >
      </v-file-input>
      <v-row v-show="getSelectedPage.image != null" class="mb-4" >
        <v-col>
          <v-tooltip left close-on-content-click >
              <template  v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="ml-10 mr-2 imagen_pequeña"
                  :style="'border:solid gray 2px; cursor:pointer'"
                  :src="getSelectedPage.image"
                  height="32px"
                  width="32px"
                  @click="cambiar_imagen_horizontal()"
              ></v-img>
              </template>
              <span>Cambiar</span>
          </v-tooltip>        
            </v-col>
            <v-col>
              <v-btn color="gray"  @click="deleteLandscapeImage"> <v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
      </v-row>
      <!-- <div v-else class=" mb-5 text-center">
          <v-btn text color="primary" @click="deleteImage">Cambiar imagen</v-btn>
      </div> -->

        <!-- IMAGEN DE FONDO EN VERTICAL  -->
        <v-divider></v-divider>
       <p class="mt-5 ml-2" :style="'color:#6B6B6B'"> Fondo Vertical:</p>
      <v-file-input v-show="!getSelectedPage.image_portrait || getSelectedPage.image_portrait == null"
          accept="image/*" 
          label="Seleccionar..."
          @change="cambiarFondoVertical($event)"
          ref="ref_pag_image_file_input2"
      >
      </v-file-input>
      <v-row v-show="getSelectedPage.image_portrait != null" class="mb-4" >
        <v-col>
          <v-tooltip left close-on-content-click >
              <template  v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  class="ml-10 mr-2 imagen_pequeña"
                  :style="'border:solid gray 2px; cursor:pointer'"
                  :src="getSelectedPage.image_portrait"
                  height="32px"
                  width="32px"
                  @click="cambiar_imagen_vertical()"
              ></v-img>
              </template>
              <span>Cambiar</span>
          </v-tooltip>        
            </v-col>
            <v-col>
              <v-btn color="gray"  @click="deletePortraitImage"> <v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
      </v-row>



      <v-divider></v-divider>
        <v-checkbox
            class="mb-5"
            label="Página principal"
            color="info"
            v-model="getSelectedPage.is_main_page"
            :disabled="getSelectedPage.is_main_page ?true:false"
            @click="changeToMainPage"
            hide-details
          ></v-checkbox>

          <!-- Selector de siguiwnte pagina para navegacion automatica en reproduccion -->
          <v-select
            v-model="getSelectedPage.next_page_id"
            :items="getPages"
            item-text='name'
            item-value='id'
            prefix="Siguiente Página:"
          ></v-select>
          <v-text-field
          prefix="z:"
          v-model="zIndex"
          type="number"
          >
         
          </v-text-field>
        <v-btn block
       @click="deleteThisPage"
       color="error"
       >
        Eliminar
       </v-btn>
       </v-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: {
    page: Object,
  },

  data() {
    return {
      new_title: "",
      modifyed: false,
      isMainPage: false,
      zIndex: 0,
    };
  }, //fin de data

  methods: {
    ...mapActions(["setPages", "unselectPage", "addModifiedPage"]),
    ...mapActions("api", [
      "deletePage",
      "deletePageLandscapeImage",
      "deletePagePortraitImage",
      "putPage",
    ]),
    cambiar_imagen_horizontal() {
      this.$refs.ref_pag_image_file_input.$refs.input.click();
    },
    cambiar_imagen_vertical() {
      this.$refs.ref_pag_image_file_input2.$refs.input.click();
    },
    async deleteLandscapeImage() {
      this.getSelectedPage.image = null;
      await this.deletePageLandscapeImage(this.getSelectedPage.id);
    },
    async deletePortraitImage() {
      this.getSelectedPage.image_portrait = null;
      await this.deletePagePortraitImage(this.getSelectedPage.id);
    },
    async deleteThisPage() {
      await this.deletePage(this.getSelectedPage.id);
      this.setPages(
        this.getPages.filter((item) => item != this.getSelectedPage)
      );
      this.unselectPage();
    },
    changeToMainPage(e) {
      this.getPages.forEach((element) => {
        // quitamos la pagina que sea principal y la asignamos a esta
        if (element != this.getSelectedPage && element.is_main_page == true) {
          element.is_main_page = false;
          this.addModifiedPage(element);
        }
      });
      this.addModifiedPage(this.getSelectedPage);
    },
    async cambiarFondo(e) {
      if (e != null) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          this.getSelectedPage.image = event.target.result;
          const res = await this.putPage(this.getSelectedPage);
        };
        reader.readAsDataURL(e);
      }
    },
    async cambiarFondoVertical(e) {
      if (e != null) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          this.getSelectedPage.image_portrait = event.target.result;
          const res = await this.putPage(this.getSelectedPage);
        };
        reader.readAsDataURL(e);
      }
    },
  },
  computed: {
    ...mapGetters(["getSelectedPage", "getPages"]),
    ...mapState(["modified_pages", "selected_page"]),
  },
  mounted() {
    this.new_title = this.getSelectedPage.name;
    this.zIndex = this.getSelectedPage.z_index;
  },
  watch: {
    getSelectedPage() {
      if (Object.keys(this.getSelectedPage).length != 0) {
        this.new_title = this.getSelectedPage.name;
      } else {
        this.new_title = "";
      }
    },
    new_title() {
      if (this.new_title != this.getSelectedPage.name) {
        this.getSelectedPage.name = this.new_title;
        this.addModifiedPage(this.getSelectedPage);
      }
    },
    zIndex() {
      if (this.zIndex != this.getSelectedPage.z_index) {
        this.getSelectedPage.z_index = this.zIndex;
        this.addModifiedPage(this.getSelectedPage);
      }
    },
    // meter esto en el de getSelectedPage
    selected_page: {
      handler(newValue, oldValue) {
        if (!_.isEqual(this.selected_page, newValue)) {
          this.addModifiedPage(this.selected_page);
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.boton {
  margin-top: 5%;
}
</style>