<template lang="">
    <!-- :style="'width:100%; height:100%; '" -->
    <v-list-item-group  class="milista"
        
        v-model="selectedIndex"
        color='green'
    >
    
        <v-list-item v-for="(item, index) in pages_list" :key="index" >
            <created-page :page="item"></created-page>
            
        </v-list-item>
    </v-list-item-group >
</template>
<script>
    import CreatedPage from '@/components/pages/CreatedPage.vue'

    import { mapGetters,mapActions } from "vuex";
export default {
    components:{
        CreatedPage,
    },
    props:
    {
        pages_list:Array
    },
    data() {
        return {
            selectedIndex:null
        }
    },//fin data

    computed: {
        ...mapGetters(['getSelectedPage', 'getPages'])
    },

    watch:{
        getSelectedPage(){
            if(this.getSelectedPage){
                let index = this.getPages.findIndex(e => e == this.getSelectedPage)
                this.selectedIndex = index
            }
        },
        selectedIndex(){
            const esnull = (typeof(this.selectedIndex)=='undefined')
            const send_item = (!esnull)?this.pages_list[this.selectedIndex]:null
            if(send_item != null){
            // if(Object.keys(send_item).length != 0){
                if(this.getSelectedPage == null || this.getSelectedPage.id != send_item.id)
                    this.setSelectedPage(send_item)
            }else{
                // this.selectedIndex = 0
                this.unselectPage()
            }
        }
    },
    methods: {
        ...mapActions(['setSelectedPage', 'unselectPage']),
        
    },
    
}
</script>
<style>
 .milista{
    height: 100%;
    width: 100%;
    /* border: solid black; */
  }
    
</style>