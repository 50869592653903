import Vue from 'vue'
// const moment = require('moment'); // Importa la biblioteca Moment.js

Vue.prototype.prototipoCajas={
    //Función que pone cajas una detrás de la otra
    stackBoxes(data, offset, value_field_name,position_field_name){
        console.log(value_field_name,position_field_name)
        let valor=offset
        let resultado=[]
        data.forEach(item => {
            console.log(item)
            const current_block = {
                inicio:valor,
                fin:item[position_field_name]+valor,
                valor:item[value_field_name]
              }
              resultado.push(current_block);
              valor+=item[position_field_name];
            
        });
        console.log(resultado)
        return resultado
    }
}