<template lang="es">
  <div>
    
    <!-- {{value}} -->
<!-- {{myProps.customizables}} -->
    <canvas v-if=" myProps.customizables.static_image || (myProps.customizables.images.length > 0 && value !== undefined)" id="myCanvas"  ref="myCanvas"></canvas>
  </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "KoioteImageSwitchState",
  props: {
    myProps: Object,
  },
  computed: {
    customizables() {
      return this.myProps.customizables
    },
    value() {
      if( this.myProps.customizables.state_property){
        const nestedProperties = this.myProps.customizables.state_property.split('.');
        // Access the nested property using reduce function on this.$store.state.api
        let valor = nestedProperties.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, this.$store.state.solar);
        return valor
      }else
        return undefined
    }
  },
  data() {
    return {
      propiedadesEnCarga:null,
      numberValue: null,
      terminado_de_cargar: false,
      cantidad_de_imagenes_cargadas: 0,
      static_loaded: false,
      loadedImages: 0,
      cargado: false,
      image: null,
      imageWidth: null,
      imageHeight: null,
      images: [],
    }
  },

 async  mounted() {
      await this.loadImages();
      this.comprobarDraw()
      // setInterval(() => {
      //     this.pruebas(Math.floor(Math.random()*2))
      // }, 2000);
  },
  methods: {
    ...mapActions('api',['pruebas']),
    ...mapActions(['updateAnyComponent', 'callDynamicFunction']),
    loadStaticImage() {
      if(this.myProps.customizables.static_image){
        const imageLoader = new Image();
        imageLoader.onload = () => {
          this.image = imageLoader;
          this.imageWidth = imageLoader.naturalWidth;
          this.imageHeight = imageLoader.naturalHeight;
          this.static_loaded = true;
        }
        imageLoader.src = this.myProps.customizables.static_image;
      }
    },

    loadImages() {
      //console.log(`Cargando imagen ${this.cantidad_de_imagenes_cargadas}...`)
      this.terminado_de_cargar = false;
      // let seted_value = this.propiedadesEnCarga.customizables.setted_values[this.cantidad_de_imagenes_cargadas]
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[this.cantidad_de_imagenes_cargadas]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values

        }
        this.images.push ( dato)
        this.terminado_de_cargar = true;
      }
      imageLoader.src = this.myProps.customizables.images[this.cantidad_de_imagenes_cargadas];

    },

    modifyImage(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: index,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }

        this.images[index] = dato
        let current_image = this.images.find(a=>a.seted_value==this.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];
    },
    loadAndDraw(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }
        this.images.push ( dato)
        this.terminado_de_cargar = true;
        let current_image = this.images.find(a=>a.seted_value==this.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];

    },
    draw(current_image){
      //console.log(current_image)
      const canvas = this.$refs.myCanvas;
      if(canvas){
        try {
          // console.log("paso a")
          const ctx = canvas.getContext("2d");
          // console.log("paso b")
          canvas.width = current_image.width
          // console.log("paso c")
          canvas.height = current_image.height
          // console.log("paso d")
          let imageLoader = current_image.imagen;
          // console.log("paso e")
          ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
          // console.log("paso f")
          if(this.myProps.customizables.border){

            const borderWidth = 8; // Change this value to adjust the border width
            const borderColor = this.myProps.customizables.border_color // Change this value to adjust the border color

            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
          }

        } catch (error) {
          let ob= this.images.filter(a=>a.index_objeto==this.myProps.id_draggable)
        }
      }
    },


    drawStatic() {
      const canvas = this.$refs.myCanvas;
      if(canvas){
        try{
          const ctx = canvas.getContext("2d");
          canvas.width = this.imageWidth
          canvas.height = this.imageHeight
          let imageLoader = this.image;
          ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
          if(this.myProps.customizables.border){
            const borderWidth = 8; // Change this value to adjust the border width
            const borderColor =this.myProps.customizables.border_color; // Change this value to adjust the border color

            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
          }
        }catch(error){
          console.log("Error en imagen estatica: ", error);
        }
      }

    },
    clearCanvas(){
      const canvas = this.$refs.myCanvas;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    comprobarDraw(){
      if(this.value === undefined)return

        //comprobar si la imagen está cargada
        let imagen_localizada = this.images.filter(a=>a.seted_value==this.value)
      if(imagen_localizada.length==0){
        //La imagen no está cargada.
        //Obtener la imagen a cargar
        let index = this.myProps.customizables.setted_values.indexOf(this.value.toString())
        if(index<0){ // no hay imagen para ese valor
          this.clearCanvas()
          return
        } 
        
        this.loadAndDraw(index)
      }
      else{

        this.draw(imagen_localizada[0])
      }

  },
  addBorder(){
    const canvas = this.$refs.myCanvas;
    if(canvas){
      try {
        const ctx = canvas.getContext("2d");
        if(this.myProps.customizables.ignore_plc && this.static_loaded){
          canvas.width = this.imageWidth
          canvas.height = this.imageHeight
        }
        else if(!this.myProps.customizables.ignore_plc){
          let current_image = this.images.filter(a=>a.seted_value==this.value)
          canvas.width = current_image.width
          canvas.height = current_image.height
        }else return
        const borderWidth = 8; // Change this value to adjust the border width
        const borderColor = this.myProps.customizables.border_color; // Change this value to adjust the border color

        ctx.strokeStyle = borderColor;
        ctx.lineWidth = borderWidth;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
      }catch(error){
        console.log("Error en borde de imagen: ", error);
      }
      }
    }
  },

  watch: {
    static_loaded() {
      if (this.static_loaded) {
        this.drawStatic();
      }
    },
    value() {
        this.comprobarDraw();
    },
    'myProps.customizables.border':function(){//myProps.customiables.border
      // if(this.hover){ // esto se quitaria
          this.comprobarDraw()

    },
    'myProps.customizables.images':function(){
      if(!this.myProps.customizables.ignore_plc && this.myProps.customizables.images.length > 0){
        //cargamos las imagenes con la actualizacion del array
        this.images = []
        this.comprobarDraw();
      }
    },
    'myProps.customizables.static_image':function(){
      if(!this.myProps.customizables.static_image && this.myProps.customizables.images.length > 0){
        this.comprobarDraw();
      }
      this.static_loaded = false
      this.loadStaticImage()
    },

  }
};
</script>
<style>
.draggable-resize-rotate #parentContainer {
  width: 100%;
  height: 100%;
  position: relative;

}
.canvasBorder{
  border: 2px solid red;
}
#myCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.fullimage {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: none;
  /* Ensures the image fills the container */

}

.round {
  border-radius: 50%;

}</style>