import KoioteVideoPlayer from './KoioteVideoPlayer.vue'
import KoioteVideoPlayerProperties from './KoioteVideoPlayerProperties.vue'
import KoioteVideoPlayerProperty from './default.json'

export default {
    install(Vue,options) {
        Vue.component('koiote-video-player', KoioteVideoPlayer);
        Vue.component('koiote-video-player-properties', KoioteVideoPlayerProperties);
        Vue.prototype.$KoioteVideoPlayerProperty = KoioteVideoPlayerProperty;

        Vue.mixin({
          });
       }
  };
  
