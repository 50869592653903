<template lang="es">
  
  <!-- <v-tooltip bottom> -->
    <!-- <template v-slot:activator="{ on }"> -->
           <div class="canvas_completo">
            <canvas ref="myCanvas" class="" width="800" height="100"></canvas>
            <canvas v-if="ejeX" ref="canvasEjeX" class="ejex"></canvas>
            <div ref="infoBox" class="mouseFollower">X</div> 
          </div>
    <!-- </template> -->
    <!-- <span>Guardar (ctrl+s)</span> -->
  <!-- </v-tooltip> -->


    
</template>
<script>
export default {
  name: "GraficaHorizontal",
  props: {
    datos: Array,
    totalPuntos: Number,
    type: String,
    ejeX: Array,
    states: Array, //array de objetos {valor,color}
  },
  data() {
    return {
      canvasWidth: null,
      canvasHeight: null,
      canvas: null,
      context: null,
      drawnAreas: [],
      // innerWidth:null,
      // innerHeight:null,
      infoBox: null,
      canvasEjeX: null,
      contextEjeX: null,
    };
  }, //fin data

  created() {
    window.addEventListener("resize", this.handleResize);
  },
  watch:{
    datos: {
      deep: true,
      handler(newValue, oldValue) {
        this.handleResize();
      }
    },
   


  },
  mounted() {
    //grafica
    this.canvas = this.$refs.myCanvas;
    this.context = this.canvas.getContext("2d");
    //eje X
    if (this.ejeX) {
      this.canvasEjeX = this.$refs.canvasEjeX;
      this.contextEjeX = this.canvasEjeX.getContext("2d");
    }

    this.infoBox = this.$refs.infoBox;
    // this.setCanvasSize();
    this.canvas.addEventListener("mousemove", this.handleMouseMove);
    this.canvas.addEventListener("mouseleave", this.handleMouseLeaver);
    
    const resizeObserver = new ResizeObserver(() => {
      this.handleResize();
    });
    resizeObserver.observe(this.canvas.parentElement);
  },

  methods: {
    handleMouseLeaver(event) {
      this.infoBox.style.display = "none";
    },
    handleMouseMove(event) {
      const x = event.clientX - this.canvas.getBoundingClientRect().left;
      const y = event.clientY - this.canvas.getBoundingClientRect().top;

      // // Comprueba si el ratón está dentro de un área donde se ha dibujado una caja
      for (const area of this.drawnAreas) {
        if (x >= area.x && x <= area.x + area.width) {
          // isMouseOverBox = true;
          this.infoBox.style.display = "block";
          break;
        }
      }

      let encontrado = false;
      this.datos.forEach((item) => {
        // const startTime = (item.fechaInicio.getHours() * 3600) + (item.fechaInicio.getMinutes() * 60) + item.fechaInicio.getSeconds();
        // const endTime =   (item.fechaFin.getHours() * 3600) + (item.fechaFin.getMinutes() * 60) + item.fechaFin.getSeconds();
        const boxX = (item.inicio / this.totalPuntos) * this.canvas.width;
        const boxWidth =
          ((item.fin - item.inicio) / this.totalPuntos) * this.canvas.width;

        if (x >= boxX && x <= boxX + boxWidth) {
          encontrado = true;
          let timeDifference = item.fin - item.inicio;
          timeDifference = timeDifference.toFixed(2);
          this.infoBox.textContent = `${item.valor}: ${timeDifference}%`;

          this.infoBox.style.left = `${x}px`;
          this.infoBox.style.top = `${y}px`;
          // Muestra miDiv
          this.mostrarMiDiv = true;

          // this.infoBox.style.top = (event.clientY) + "px";
        } else {
          //console.log("noooo")
          // infoBox.style.display = "none";
        }
      });
      this.infoBox.style.display = encontrado ? "block" : "none";
    },
    setCanvasSize() {
      const div = this.canvas.parentElement
      // this.canvas.width = window.innerWidth-20;
      this.canvas.width = div.clientWidth
      this.canvas.height = div.clientHeight
      if (this.ejeX) {
        this.canvasEjeX.width = this.canvas.width;
        this.canvasEjeX.height = this.canvas.height
      }
      this.$emit("resize", this.canvasEjeX);
    },
    handleResize() {
      this.setCanvasSize();
      this.innerWidth = window.innerWidth;
      this.innerHeight = window.innerHeight;
      this.drawBoxes();
      if (this.ejeX) this.drawAxis();
    },
    drawAxis() {
      this.contextEjeX.clearRect(
        0,
        0,
        this.canvasEjeX.width,
        this.canvasEjeX.height
      );
      this.contextEjeX.font = "12px Arial"; // Configura la fuente y el tamaño del texto
      this.contextEjeX.fillStyle = "blue"; // Establece el color del texto
      const angleInDegrees = -25;
      const angleInRadians = (angleInDegrees * Math.PI) / 180;

      this.ejeX.forEach((item, index) => {
        const textWidth = this.contextEjeX.measureText(item).width;
        //                console.log(textWidth)

        const x = (index * this.canvasEjeX.width) / this.ejeX.length;
        const y = 20; //this.canvasEjeX.height/2; // Espacio vertical entre cadenas
        //console.log(x,y)
        this.contextEjeX.translate(x, y);
        // Dibuja la línea
        this.contextEjeX.lineWidth = 1; // Ancho del trazo en píxeles (ajusta el valor según lo que consideres "fino")

        this.contextEjeX.beginPath();
        this.contextEjeX.moveTo(0, -30); // Establece el punto de inicio
        this.contextEjeX.lineTo(0, -5); // Dibuja hasta el punto final
        this.contextEjeX.stroke(); // Realiza el trazo
        this.contextEjeX.closePath();

        // Aplica una rotación a cada cadena
        this.contextEjeX.rotate(angleInRadians);

        this.contextEjeX.fillText(item, -textWidth, 0); // Dibuja el texto en (50, 100)
        this.contextEjeX.setTransform(1, 0, 0, 1, 0, 0);
      });
    },

    drawBoxes() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.datos.forEach((item) => {
        const boxX = (item.inicio / this.totalPuntos) * this.canvas.width;
        const boxWidth =
          ((item.fin - item.inicio) / this.totalPuntos) * this.canvas.width;
        const current_color = this.getColor(item.valor);

        this.context.fillStyle = current_color;

        this.context.fillRect(boxX, 0, boxWidth, this.canvas.height);
        this.drawnAreas.push({ x: boxX, width: boxWidth });
      });
    },

    getColor(valor) {
      const resultado = this.states.filter((a) => a.valor == valor);
      if (resultado.length) return resultado[0].color;
    },
  },
};
</script>
<style>
canvas {
  border: 0px solid #000;
}
#infoBox {
  position: absolute;
  display: none;
  background: #fff;
  padding: 5px;
  border: 1px solid #000;
}
.ejex {
  margin-top: -6px;
  width: 100%;
  height: 100%;
}
/* .mouseFollower {
  position: fixed;
  background-color: #ccc;
  width: 100px;
  height: 50px;
  left: 138px;
  top: 101px;
  display: none;
} */
.canvas_completo{
    width:100%;
    height: 100%;
  }
.mouseFollower{
  background-color: rgba(0, 0, 0, 0.7); /* Black with 70% transparency */
  color: white;
  border-radius: 10px; /* Adjust the radius for rounded corners */
  padding:10px;
  display:none;
  position:fixed;
}
</style>