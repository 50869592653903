import KoioteGraficoHorizontal from './KoioteGraficoHorizontal.vue'
import GraficaHorizontal from './GraficaHorizontal.vue'
import KoioteGraficoHorizontalProperties from './KoioteGraficoHorizontalProperties.vue'
import KoioteGraficoHorizontal1Property from './default.json'
import './prototiposFechas.js'
import './prototiposCajas'

import { Bar } from 'vue-chartjs'

export default {
    install(Vue,options) {
      Vue.component(GraficaHorizontal.name, GraficaHorizontal)
      Vue.component(KoioteGraficoHorizontal.name, KoioteGraficoHorizontal)
      Vue.component(KoioteGraficoHorizontalProperties.name, KoioteGraficoHorizontalProperties)
      Vue.prototype.$GraficoHorizontalProperties = KoioteGraficoHorizontal1Property

      Vue.mixin({
          mounted() {
            // console.log('Montadito!');
          }
        });
      }
  };
  
