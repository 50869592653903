import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'


Vue.use(VueAxios, axios)

// Axios interceptors for checking session token
import interceptorsSetup from './utils/axiosInterceptors'
interceptorsSetup()

//Libreria necesaria para poder comprobar si dos objetos son iguales
import _ from 'lodash';
import VueResizeObserver from "vue-resize-observer";
Vue.use(VueResizeObserver);


//Elemento draggable, resizeable y girable
import KoioteDraggableResizable from '@/components/generic/KoioteDraggableResizable.vue'
Vue.component('koiote-draggable-reseizeable', KoioteDraggableResizable)

import KoioteBasicElement from '@/components/generic/KoioteBasicElement.vue'
Vue.component('koiote-basic-element', KoioteBasicElement)


//Para la barra de propiedades y herramientas
import VueDragResize from 'vue-drag-resize';
Vue.component('VueDragResize',VueDragResize)

import vuetify from './plugins/vuetify'

import KoioteLabel from "./plugins/label/KoioteLabelPlugin.js"
Vue.use(KoioteLabel)
import KoioteNavigation from "./plugins/navigation/KoioteNavigationPlugin.js"
Vue.use(KoioteNavigation)
import KoioteValueLabelState from "./plugins/value_label_state/KoioteValueLabelStatePlugin.js"
Vue.use(KoioteValueLabelState)
import KoioteImageSwitchHover from "./plugins/image-switch-hover/KoioteImageSwitchHoverPlugin.js"
Vue.use(KoioteImageSwitchHover)
import KoioteVideoPlayer from "./plugins/video-player/KoioteVideoPlayerPlugin.js"
Vue.use(KoioteVideoPlayer)
import KoioteGraficoLineal from "./plugins/graficolineal/KoioteGraficoLinealPlugin.js"
Vue.use(KoioteGraficoLineal)
import KoioteGraficoHorizontal from "./plugins/graficoHorizontal/KoioteGraficoHorizontalPlugin.js"
Vue.use(KoioteGraficoHorizontal)
import KoioteImageSwitchCanvas from "./plugins/image-switch-canvas/KoioteImageSwitchCanvasPlugin.js"
Vue.use(KoioteImageSwitchCanvas)
import KoioteImageSwitchState from "./plugins/image-switch-state/KoioteImageSwitchStatePlugin.js"
Vue.use(KoioteImageSwitchState)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
