// prototypes.js

import Vue from 'vue'
import moment from 'moment';


Vue.prototype.prototipoFechas = {
  converir_fechas_en_posiciones(datos,fecha_origen_field_name,fecha_fin_origen_field_name){
      const resultado = datos;
      resultado.map(item => {
        const fecha_origen = item[fecha_origen_field_name];
        const fecha_fin = item[fecha_fin_origen_field_name];
        item.inicio = (fecha_origen.getHours() * 3600) + (fecha_origen.getMinutes() * 60) + fecha_origen.getSeconds()
        item.fin = (fecha_fin.getHours() * 3600) + (fecha_fin.getMinutes() * 60) + fecha_fin.getSeconds();
        return item;
      });
      return resultado
    },

    suma_diferencia_fechas_con_mismo_valor(datos,totalSeconds,fecha_origen_field_name,fecha_fin_origen_field_name,value_field_name) {
        const FECHA_ORIGEN_FIELD=fecha_origen_field_name
        const FECHA_DESTINO_FIELD=fecha_fin_origen_field_name
        const VALUE_FIELD=value_field_name

        // Paso 1: Agrupa los elementos por el campo "valor"
        const groupedData = datos.reduce((groups, item) => {
          groups[item[value_field_name]] = groups[item[value_field_name]] || [];
          groups[item[VALUE_FIELD]].push(item);
          return groups;
        }, {});
        console.log("SUMA: ", groupedData);
        // Paso 2: Calcula la suma de las diferencias entre fechas
        const result = Object.entries(groupedData).map(([valor, group]) => ({
          valor,
          sumaDiferencias: group.reduce((sum, item) => {
            const diff = item[FECHA_DESTINO_FIELD] - item[FECHA_ORIGEN_FIELD];
            return sum + diff/totalSeconds/1000*100;
          }, 0),
        }));
        console.log("RESULTADO:", result)
  
        return result;
      },


      //Función que devuelve un array de moments con pasos de tiempo que se necesite
      createArrayOfMoments(canvas, fecha_inicio,fecha_fin,valor,unidades,formato,auto=false){
        const dateArray = [];
        // const startDate = moment(fecha_inicio); // Fecha de inicio
        const startDate = fecha_inicio// Fecha de inicio
        const endDate = fecha_fin   // Fecha de fin
        // const endDate = moment(fecha_fin);   // Fecha de fin
        let unitIncremented = valor; 
        // let currentDate = startDate.clone();
        let currentDate = startDate
        let unidades_aplicados = unidades
        if(auto){
          console.log("AUTOOOOOOOOOOOOOOOOO");
          if(canvas){
            console.log(`canvas width es ${canvas.width}`)
            const numero_de_puntos = endDate.diff(startDate, unidades)+1;
            console.log(numero_de_puntos)
            console.log(canvas.width/numero_de_puntos)
            if(canvas.width/numero_de_puntos<25){
              console.log("APLICAAA")
              unitIncremented=valor*2;
            }else{
              console.log("NO APLIQUESSSSSSSSSS")
            }
            console.log(unitIncremented)

          }
        }
  
        while (currentDate <100) {
            const current_value = currentDate
            dateArray.push(current_value)
            currentDate = currentDate + unitIncremented
        }
        return dateArray
      }
}

