<template lang="es">
     <div :style="myStyle" class="valuelabelstate">
        <!-- <p id="value1-value-label"> -->
          {{value}}{{myProps.customizables.unities?myProps.customizables.unities:''}}
        <!-- {{myProps.customizables.state_property}} -->
       <!-- </p> -->
    </div>
</template>
<script>
import moment from 'moment-timezone';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
  name: "KoioteValueLabelState",
  props: {
    myProps: Object,
    h:Number,
    w:Number
  },
  computed: {
    ancho(){
        let minimo = Math.min(this.h, this.w)
        if(document.documentElement.clientWidth >= 700)
            return minimo/2
        else
            return minimo/4
    },
    myStyle() {
      return {
        backgroundColor: this.myProps.customizables.background_color,
        color: this.myProps.customizables.foreground_color,
        border: "1px solid " + this.myProps.customizables.border_color,
        // fontSize: this.ancho+'px',
        width: "100%",
        height: "100%",
        position: "absolute",
        alignContent: "center",
        textAlign: "center",
        // lineHeight: "normal",
      };
    },
    value(){
      const nestedProperties = this.myProps.customizables.state_property.split('.');
      // Access the nested property using reduce function on this.$store.state.api
      let valor = nestedProperties.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, this.$store.state.solar);
      if(this.myProps.customizables.data_type == 'Numero'){
        valor = (parseFloat(valor)*(this.myProps.customizables.scale)).toFixed(this.myProps.customizables.decimals)
      }
      else if(this.myProps.customizables.data_type == 'Fecha'){
        // const inputMoment = moment.tz(valor, 'Europe/Madrid');
        valor = moment(valor).format('YYYY-MM-DD HH:mm:ss');      
      }
      return valor

    },

  },
  watch:{
    value(){
      if(this.value != undefined){
        this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
      }
    },
   }
};
</script>
<style>
.valuelabelstate{
  margin: 0;
  padding: 0;
  font-weight: bolder;
  font-size: 1.5vw;
  font-family: 'Cairo', sans-serif!important;
  /* font-family: 'Tajawal', sans-serif!important; */
  /* line-height: 0.2; */
}

@media (orientation: portrait) {
  .valuelabelstate {
    font-size: 1.5vw;
  }
}

@media (orientation: landscape) and (max-width: 600px) {
  .valuelabelstate {
    font-size: 0.85vw;
  }
}

@media (orientation: portrait) and (max-width: 500px) {
  .valuelabelstate {
    font-size: 2.5vw;
  }
}
</style>