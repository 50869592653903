<template lang="es">
  <v-card text>
    <v-list>
      <v-list-item-group>

        <!-- Texto -->
        <v-list-item>
          <v-list-item-content>
            <v-text-field 
              label="Texto"
              :clearable=true
              v-model="nuevo_texto"
              @keyup.enter="cambio_texto"
              :clear-icon-cb="nuevo_texto == null? changeEditing(true):null"
              @click="changeEditing(true)"
              @blur="changeEditing(false)"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Primera variable..." v-model="component_selected.component_props.customizables.state_property1" @keyup.enter="changeEditing(false)" @click="changeEditing(true)" @blur="changeEditing(false)"> </v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Segunda variable..." v-model="component_selected.component_props.customizables.state_property2" @keyup.enter="changeEditing(false)" @click="changeEditing(true)" @blur="changeEditing(false)"> </v-text-field>
          </v-list-item-content>
        </v-list-item>


        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Nombre primera variable..." v-model="component_selected.component_props.customizables.label_state_property1" @keyup.enter="changeEditing(false)" @click="changeEditing(true)" @blur="changeEditing(false)"> </v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Nombre segunda variable..." v-model="component_selected.component_props.customizables.label_state_property2" @keyup.enter="changeEditing(false)" @click="changeEditing(true)" @blur="changeEditing(false)"> </v-text-field>
          </v-list-item-content>
        </v-list-item>
        <!-- color_state_property1 -->
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Color primera variable" 
              :pcolor="nuevo_programa_border"
              @event="cambio_programa_color"
              @blur="changeEditing(false)"
              >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
        
        <!-- color_state_property2 -->
        <v-list-item>
          <v-list-item-content>
            <koiote-color-picker-vue titulo="Color segunda variable" 
              :pcolor="nuevo_color_state_property2"
              @event="cambio_color_state_property2"
              @blur="changeEditing(false)"
              >
            </koiote-color-picker-vue>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import KoioteColorPickerVue from '@/components/custom/KoioteColorPicker.vue';
export default {
  name: "KoioteGraficoHorizontalProperties",
  components: {
    KoioteColorPickerVue
  },
  data() {
    return {
      nuevo_objeto: null,
      nuevo_texto: null,
      nuevo_programa_border: null,
      nuevo_color_state_property2: null,
    }
  },
  computed: {
    ...mapState(['component_selected']),
  },
  watch: {
    component_selected: {
      deep: true,
      handler() {
        this.nuevo_texto = this.component_selected.component_props.customizables.text
        this.nuevo_programa_border = this.component_selected.component_props.customizables.color_state_property1
        this.nuevo_color_state_property2 = this.component_selected.component_props.customizables.color_state_property2
      }
    },
  },
  methods: {
    ...mapActions(['changeEditing']),
    cambio_texto() {
      this.component_selected.component_props.customizables.text = this.nuevo_texto
      this.changeEditing(false)
      console.log(this.component_selected)
    },
    cambio_programa_color(nc){
      this.component_selected.component_props.customizables.color_state_property1 = nc
    },
    cambio_color_state_property2(nc){
      this.component_selected.component_props.customizables.color_state_property2 = nc
    },
  },
  created() {
    this.nuevo_objeto = JSON.parse(JSON.stringify(this.component_selected.component_props));
    this.nuevo_texto = this.component_selected.component_props.customizables.text
    this.nuevo_programa_border = this.component_selected.component_props.customizables.color_state_property1
    this.nuevo_color_state_property2 = this.component_selected.component_props.customizables.color_state_property2
  },
}
</script>

<style lang="">
</style>