<template lang="">
    <div>
      <v-navigation-drawer ref="drawer" id="frente" app right :width="navigation.width" v-model="navigationShow">
        <!-- titulo -->
        <v-toolbar color="white">
          <v-toolbar-title class="headline">
            <span >Propiedades</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon  v-if="show_navigation" @click="setShowNavigation(false)">mdi-close</v-icon>
      
        </v-toolbar>
        <!-- <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h5"> Editor </v-list-item-title>
          <v-list-item-subtitle> Navigation drawers</v-list-item-subtitle> 
        </v-list-item-content>
      </v-list-item> -->
      <v-divider></v-divider>
      <div v-if="withChild">
        <!-- Menú de propiedades-->
        <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Componente {{component_selected.id}}</v-list-item-title>
          <v-btn block class="mt-4" @click="toggle_posicion()">Posición<v-icon class="ml-2">{{show_posicion?'mdi-arrow-up':'mdi-arrow-down'}}</v-icon></v-btn>
          <v-simple-table v-if="show_posicion">
            
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>x</td>
                  <td>
                    <koiote-editable-input @cambio="cambio_propiedad" propiedad="x" :valor="component_selected.x"></koiote-editable-input>
                  </td>
                </tr>
                <tr>
                  <td>y</td>
                  <td>
                    <koiote-editable-input @cambio="cambio_propiedad" propiedad="y" :valor="component_selected.y"></koiote-editable-input>
                  </td>
                </tr>
                <tr>
                  <td>h</td>
                  <td>
                    <koiote-editable-input @cambio="cambio_propiedad" propiedad="h" :valor="component_selected.h"></koiote-editable-input>
                  </td>
              </tr>
              <tr>
                <td>w</td>
                <td>
                  <koiote-editable-input @cambio="cambio_propiedad" propiedad="w" :valor="component_selected.w"></koiote-editable-input>
                </td>
              </tr>
              <tr>
                <td>angle</td>
                <td>
                  <koiote-editable-input @cambio="cambio_propiedad" propiedad="angle" :valor="component_selected.angle"></koiote-editable-input>
                </td>
              </tr>
              <tr>
                <td>z</td>
                <td>
                  <koiote-editable-input @cambio="cambio_propiedad" propiedad="z" :valor="component_selected.z"></koiote-editable-input>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-checkbox
    class="ml-2 mb-2"
    label="Mostrar al ejecutar"
    color="info"
    v-model="component_selected.component_props.show_in_exec"
    hide-details
    ></v-checkbox>
    <component ref="childComponent"
    :is="component_selected.component_props.component_property_window">
  </component>
  <!-- <koiote-plc-selection class="mt-8" v-if="component_selected.component_props.customizables.plc != undefined"></koiote-plc-selection> -->

  <div class="text-center" >
          <v-btn class="btn mt-4"
          rounded
          color="error"
          dark
          @click="deleteDraggable"
          >
          Eliminar
          <v-icon
          right
          dark
          >
          mdi-delete
        </v-icon>
      </v-btn>
    </div>
    <v-divider
      :thickness="3"
      class="mt-4 border-opacity-100"
    ></v-divider>
      </div>
      <properties-page v-if="Object.keys(getSelectedPage).length != 0 && Object.keys(component_selected).length == 0" ></properties-page>
   
      </v-navigation-drawer>
    <v-layout justify-end align-center class="centrado_vertical">
      <v-btn style="transform:rotate(90deg)" v-if="!navigationShow" @click="setShowNavigation(true)" class="mr-n4" ><v-icon>mdi-chevron-down</v-icon></v-btn>
    </v-layout>
    </div>
</template>
<script>
import KoioteEditableInput from "@/components/custom/KoioteEditableInput.vue";
import PropertiesPage from "@/components/pages/PropertiesPage.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data: () => {
    return {
      navigation: {
        // shown: false,
        // width: 0,
        borderSize: 3,
        width: 300,
      },
      left: 0,
      height: 0,
      top: 0,
      show_posicion: false,
    };
  },
  components:{
    KoiotePlcSelection: () =>
      import("@/components/custom/KoiotePlcSelection.vue"),
    PropertiesPage,
    KoioteEditableInput,
  },
  computed: {
    ...mapState(["component_selected", "list_components", "component_selected_original", 'editing', 'show_navigation']),
    ...mapGetters(["getSelectedPage"]),
    direction() {
      return this.show_navigation === false ? "<" : "Closed";
    },
    withChild() {
      return Object.keys(this.component_selected).length != 0;
    },
    navigationShow: {
      get () {
        return this.show_navigation
      },
      set (value) {
        this.setShowNavigation(value)
      }
    }
  },
  methods: {
    ...mapActions([
      "deleteDraggable",
      "addModifiedDraggable",
      "setSelectedComponentsNewPosition",
      'changeEditing',
      'setShowNavigation'
    ]),
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    cambio_propiedad(nuevo_valor) {
      console.log("Cambio en propiedad generica");
      console.log(nuevo_valor);
      this.setSelectedComponentsNewPosition(nuevo_valor);
      this.addModifiedDraggable(this.component_selected)
    },
    toggle_posicion() {
      this.show_posicion = !this.show_posicion;
    },
    setBorderWidth() {
      let i = this.$refs.drawer.$el.querySelector(
        ".v-navigation-drawer__border"
      );
      i.style.width = this.navigation.borderSize + "px";
      i.style.cursor = "ew-resize";
    },
    setEvents() {
      const minSize = this.navigation.borderSize;
      const el = this.$refs.drawer.$el;
      const drawerBorder = el.querySelector(".v-navigation-drawer__border");
      const vm = this;
      const direction = el.classList.contains("v-navigation-drawer--right")
        ? "right"
        : "left";

      function resize(e) {
        document.body.style.cursor = "ew-resize";
        let f =
          direction === "right"
            ? document.body.scrollWidth - e.clientX
            : e.clientX;
        el.style.width = f + "px";
      }

      drawerBorder.addEventListener(
        "mousedown",
        function (e) {
          if (e.offsetX < minSize) {
            //m_pos = e.x;
            el.style.transition = "initial";
            document.addEventListener("mousemove", resize, false);
          }
        },
        false
      );

      document.addEventListener(
        "mouseup",
        function () {
          el.style.transition = "";
          vm.navigation.width = el.style.width;
          document.body.style.cursor = "";
          document.removeEventListener("mousemove", resize, false);
        },
        false
      );
    },
  },
  watch: {
    // 'navigation.shown':function(){
    //   console.log("Navigation: "+this.navigation.shown);
    //   this.setShowNavigation(navigation.shown)
    // },
    component_selected: {
      deep: true,
      handler(newValue, oldValue) {
        if (Object.keys(this.component_selected).length == 0) {
          return;
        }
        if (!_.isEqual(this.component_selected, this.component_selected_original)) {
          console.log("ha habido cambio en component selected segun las properties")
          this.addModifiedDraggable(this.component_selected)
        }
      }
    }
  },
  mounted() {
    this.setBorderWidth();
    this.setEvents();
  },
};
</script>
<style>
.centrado_vertical {
  right: 0%;
  position: absolute;
  top: 50%;
  z-index: 1000;
}

#texto_blanco{
  color: white;
}
#frente{
  z-index: 200;
}
</style>