import KoioteValueLabelState from './KoioteValueLabelState.vue'
import KoioteValueLabelStateProperties from './KoioteValueLabelStateProperties.vue'
import KoioteValueLabelState1Property from './default.json'

export default {
    install(Vue, options) {
        Vue.component(KoioteValueLabelState.name, KoioteValueLabelState)
        Vue.component(KoioteValueLabelStateProperties.name, KoioteValueLabelStateProperties)
        Vue.prototype.$ValueLabelStateProperties = KoioteValueLabelState1Property

        Vue.mixin({
            mounted() {
                // console.log('Montadito!');
            }
        });
    }
};

