<template>
  <LineChartGenerator
    :options="chartOptions"
    :data="data"
    id="myChart"
    :styles="styles"
  />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs'
// import annotationPlugin from 'chartjs-plugin-annotation';
import 'chartjs-adapter-moment'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  TimeScale
} from 'chart.js'

ChartJS.register(  Title,
  Tooltip,
  LineElement,
  LinearScale,
  PointElement,
  Filler,
  // annotationPlugin,
  TimeScale,
  CategoryScale)

export default {
  name: 'LineChart',
  components: {
    LineChartGenerator
  },
  props: {
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: 'Inserte el título'
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  // methods:{
  //   drawVerticalLine(){
  //       // let x = chart.tooltip._active[0].element.x;
  //       // let yAxis = chart.scales.y;
  //       let ctx = document.getElementById('myChart');
  //       // let ctx = chart.ctx;
  //       ctx.save();
  //       ctx.beginPath();
  //       // ctx.moveTo(x, yAxis.top);
  //       // ctx.lineTo(x, yAxis.bottom);
  //       ctx.lineWidth = 1;
  //       ctx.strokeStyle = '#ff0000';
  //       ctx.stroke();
  //       ctx.restore();
  //   }
  // },

  computed: {
    chartOptions() {
      let options = {
        
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 0.5,
       
        plugins:{
         
          tooltip: {
            mode: 'index',
            intersect: false,
            callbacks: {
              // title: function() {
              //     return 'Producción:';
              // },
              // afterTitle: ((tooltipItems, data) => {
              //     return data.datasets[0].label + ':' + tooltipItems[0].yLabel + '\n' +
              //             data.datasets[1].label + ':' + tooltipItems[1].yLabel + '\n' +
              //             'Consumo:\n' +
              //             data.datasets[2].label + ':' + tooltipItems[2].yLabel + '\n' +
              //             data.datasets[3].label + ':' + tooltipItems[3].yLabel;
              // }),
  
              label: (( data) => {
                // console.log(data);
                if(data.dataset.label == 'De la red' || data.dataset.label == 'A la red'){
                  // let v = data.formattedValue.replace(/,/g, '');
                  return data.dataset.label +': '+Math.abs(parseFloat(data.raw.real))+'W'   
                }
                else{// mostramos el valor real, no el de la suma que es el que aparece en el grafico para que se vea por encima del otro
                  // let v = data.formattedValue.replace(/,/g, '');
                  return data.dataset.label +': '+Math.abs(parseFloat(data.raw.y))+'W' 
                }
              })
            }
        
          },
          title: {
            display: false,
            text: this.title,
          },
          legend: {
            display: false
          },
          datalabels: {
            display: false,
          },
        },
        scales: {
            y: {
              ticks: {
                callback: function(value) {
                  return Math.abs(value); // Display positive values on the y-axis
                },
              },
            },
          
          x: {
            type: 'time',
            time: {
              displayFormats: {
                quarter: 'MMM YYYY'
              }
            }
          }
        }
      }
      return options
    }
  },
}
</script>
