<template lang="es">
  <!-- <div> -->
<!-- {{myProps.customizables}} -->
    <canvas
    @mouseover="getIsPlayMode && myProps.customizables.hover_function?mouseFunction(true):null" 
                @mouseleave="getIsPlayMode && myProps.customizables.hover_function?mouseFunction(false):null" 
    v-if=" this.myProps.customizables.static_image || this.myProps.customizables.images.length > 0" id="myCanvas"  ref="myCanvas"></canvas>
  <!-- </div> -->
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "KoioteImageSwitch",
  props: {
    myProps: Object,
  },
  computed: {
    ...mapGetters(["getIsPlayMode"]),
    customizables() {
      return this.myProps.customizables
    },
    bufferPos() {
      if (this.myPlc) {
        let plcType = this.myPlc.type.replace(' ', '') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate' + plcType + 'BufferPos'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    dataType() {
      if (this.myPlc) {
        let plcType = this.myPlc.type.replace(' ', '') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
        let functionName = 'calculate' + plcType + 'VarType'
        return this[functionName](this.myProps.customizables, this.myPlc)
      }
    },
    myPlc() {
      if (this.myProps.customizables.topic != "") {
        return this.getConnections.find(e => e.id == this.myProps.customizables.plc)
      }
    },

    value() {
      let miMensaje = this.ListOfmessages.find(a=>a.topic==this.myProps.customizables.topic)
      if(miMensaje && this.myProps.customizables.static_image == null){ // si tiene imagen estatica no leera ningun valor
        if(this.myPlc && Object.keys(this.myPlc).length > 0 ){
          let functionName = this.myPlc.type.replace(' ','') // quitamos el espacio del tipo de PLC y ejecutamos la funcion
          functionName = 'readValueFrom'+functionName
          return this[functionName](miMensaje,this.myProps.customizables, this.bufferPos, this.dataType, this.myPlc)
        }
      }else{
        return undefined;
      }
    }
  },
  data() {
    return {
      propiedadesEnCarga:null,
      numberValue: null,
      terminado_de_cargar: false,
      cantidad_de_imagenes_cargadas: 0,
      static_loaded: false,
      loadedImages: 0,
      cargado: false,
      image: null,
      imageWidth: null,
      imageHeight: null,
      images: [],
    }
  },
 
  mounted() {
    // if(this.myProps.id_draggable == 1033){

      // this.myProps.customizables.hover_function = ''
      // this.myProps.customizables.hover_function_payload = {id_array:[]}
      // this.myProps.customizables.border = false
      
    // }
    // if (this.myProps.customizables.ignore_plc) {
      this.loadStaticImage();
    // } else {
    //   this.comprobarDraw();
    // }

    // this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
  },

  /*
  this.imageWidth = img.naturalWidth;
          this.imageHeight = img.naturalHeight;
  */
  methods: {
    ...mapActions(['updateAnyComponent', 'callDynamicFunction']),
    mouseFunction(v){
        this.myProps.customizables.hover_function_payload.value = v 
        this.callDynamicFunction(this.myProps.customizables)
    },
    loadStaticImage() {
      if(this.myProps.customizables.static_image){

        const imageLoader = new Image();
        imageLoader.onload = () => {
          this.image = imageLoader;
          this.imageWidth = imageLoader.naturalWidth;
          this.imageHeight = imageLoader.naturalHeight;
          this.static_loaded = true;
        }
        imageLoader.src = this.myProps.customizables.static_image;
      }
    },

    loadImages() {
      //console.log(`Cargando imagen ${this.cantidad_de_imagenes_cargadas}...`)
      this.terminado_de_cargar = false;
      // let seted_value = this.propiedadesEnCarga.customizables.setted_values[this.cantidad_de_imagenes_cargadas]
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[this.cantidad_de_imagenes_cargadas]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
          
        }
        // if(this.myProps.id_draggable==913){
        //   console.log("dato")
        //   console.log(dato)
        //   console.log(`Carganda imagen ${this.cantidad_de_imagenes_cargadas}!`)
        //   console.log("")
        // }
       

        this.images.push ( dato)
        this.terminado_de_cargar = true;
      }

      imageLoader.src = this.myProps.customizables.images[this.cantidad_de_imagenes_cargadas];

    },

    modifyImage(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: index,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }

        this.images[index] = dato
        let current_image = this.images.find(a=>a.seted_value==this.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];
    },
    loadAndDraw(index){
      let imageLoader = new Image();
      imageLoader.onload = () => {
        let seted_value = this.myProps.customizables.setted_values[index]
        const dato = {
          index_objeto:this.myProps.id_draggable,
          index: this.cantidad_de_imagenes_cargadas,
          imagen: imageLoader,
          width: imageLoader.naturalWidth,
          height: imageLoader.naturalHeight,
          seted_value: parseInt(seted_value),
          los_seted_values:this.myProps.customizables.setted_values
        }
        this.images.push ( dato)
        this.terminado_de_cargar = true;
        let current_image = this.images.find(a=>a.seted_value==this.value)
        this.draw(current_image)

      }

      imageLoader.src = this.myProps.customizables.images[index];

    },
    draw(current_image){
      //console.log(current_image)
      const canvas = this.$refs.myCanvas;
      if(canvas){
        try {
          // console.log("paso a")
          const ctx = canvas.getContext("2d");
          // console.log("paso b")
          canvas.width = current_image.width
          // console.log("paso c")
          canvas.height = current_image.height
          // console.log("paso d")
          let imageLoader = current_image.imagen;
          // console.log("paso e")
          ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
          // console.log("paso f")
          if(this.myProps.customizables.border){

            const borderWidth = 8; // Change this value to adjust the border width
            const borderColor = this.myProps.customizables.border_color // Change this value to adjust the border color
            
            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
          }
          
        } catch (error) {
          let ob= this.images.filter(a=>a.index_objeto==this.myProps.id_draggable)
          console.log("El objeto roto es")
          console.log(ob)
        }
      }
    },


    drawStatic() {
      const canvas = this.$refs.myCanvas;
      if(canvas){
        try{
          const ctx = canvas.getContext("2d");
          canvas.width = this.imageWidth
          canvas.height = this.imageHeight
          let imageLoader = this.image;
          ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
          if(this.myProps.customizables.border){
            console.log("A pintar borde en static");
            const borderWidth = 8; // Change this value to adjust the border width
            const borderColor =this.myProps.customizables.border_color; // Change this value to adjust the border color

            ctx.strokeStyle = borderColor;
            ctx.lineWidth = borderWidth;
            ctx.strokeRect(0, 0, canvas.width, canvas.height);
          }
        }catch(error){
          console.log("Error en imagen estatica: ", error);
        }
      }

    },
    comprobarDraw(){
      if(this.value === undefined)return 
       
        //comprobar si la imagen está cargada
        let imagen_localizada = this.images.filter(a=>a.seted_value==this.value)
      if(imagen_localizada.length==0){
        //La imagen no está cargada.
        //Obtener la imagen a cargar
        let index = this.myProps.customizables.setted_values.indexOf(this.value.toString())
        if(index<0) return
        this.loadAndDraw(index)
      }
      else{
        this.draw(imagen_localizada[0])
      }
    
  },
  addBorder(){
    const canvas = this.$refs.myCanvas;
    if(canvas){
      try {
        const ctx = canvas.getContext("2d");
        if(this.myProps.customizables.ignore_plc && this.static_loaded){
          canvas.width = this.imageWidth
          canvas.height = this.imageHeight
        }
        else if(!this.myProps.customizables.ignore_plc){
          let current_image = this.images.filter(a=>a.seted_value==this.value)
          canvas.width = current_image.width
          canvas.height = current_image.height
        }else return
        //   let imageLoader = this.image;
        //   ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
        // }
      //   const ctx = canvas.getContext("2d");
      //  // canvas.width = current_image.width
      //  // canvas.height = current_image.height
      //   let imageLoader = current_image.imagen;
      //   ctx.drawImage(imageLoader, 0, 0, canvas.width, canvas.height);
        const borderWidth = 8; // Change this value to adjust the border width
        const borderColor = this.myProps.customizables.border_color; // Change this value to adjust the border color

        ctx.strokeStyle = borderColor;
        ctx.lineWidth = borderWidth;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);
      }catch(error){
        console.log("Error en borde de imagen: ", error);
      }
      }
    }
  },

  watch: {
    static_loaded() {
      if (this.static_loaded) {
        this.drawStatic();
      }
    },
    'myProps.customizables.border':function(){//myProps.customiables.border
      // if(this.hover){ // esto se quitaria
        if(this.myProps.customizables.ignore_plc){
          if(this.static_loaded) this.drawStatic()
          else this.loadStaticImage()
        }else{
          this.comprobarDraw()
        }

        // if(this.myProps.customizables.border){
          // this.addBorder();
          // }
      
    },
    // value() {
    //   this.$emit('cambioValor', {id: this.myProps.id_draggable, value: this.value})
    //   if(!this.myProps.customizables.ignore_plc && this.myProps.customizables.images.length > 0){
    //     this.comprobarDraw();
    //   }
    // },
    'myProps.customizables.length':function(){
    },
    'myProps.customizables.images':function(){
      if(!this.myProps.customizables.ignore_plc && this.myProps.customizables.images.length > 0){
        // if(this.images.length > 0){
        //   if(this.images.length == this.myProps.customizables.images.length){
        //     //miramos de una en una pa ver cual es distinta
            // console.log("Son iguales de tamaño");
        //     for (let index = 0; index < this.myProps.customizables.images.length; index++) {
        //       if (this.myProps.customizables.images[index] != this.images[index])
        //         this.modifyImage(index)
        //     }
        //   }else if(this.myProps.customizables.images.length > this.images.length){
        //     console.log("this.images es mas txikito que las props");
        //     console.log((this.myProps.customizables.images.length));
        //     console.log((this.images.length));
        //     console.log(this.images);
        //     //cargar laultima de myprops
        //   }else{
        //     console.log("Se ha borrado una imagen???");
        //   }
        // }

        //cargamos las imagenes con la actualizacion del array
        this.images = []
        this.comprobarDraw();


      }
    },
    'myProps.customizables.static_image':function(){
      if(!this.myProps.customizables.static_image && this.myProps.customizables.images.length > 0){
        this.comprobarDraw();
      }
      this.static_loaded = false
      console.log("Voy a cargarla");
      this.loadStaticImage()
    },
    
  }
};
</script>
<style>
.draggable-resize-rotate #parentContainer {
  width: 100%;
  height: 100%;
  position: relative;

}
.canvasBorder{
  border: 2px solid red;
}
#myCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}

.fullimage {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: none;
  /* Ensures the image fills the container */

}

.round {
  border-radius: 50%;

}</style>