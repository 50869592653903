<template lang="es">
     <div class="canvas_completo">
        <grafica-lineal
            :data="data"
            :title="myProps.customizables.text"
            v-if="!loading"
        >
        </grafica-lineal>
        <v-overlay :value="loading"> <!--Es absolute por defecto, no hace falta ponerlo-->
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import GraficaLineal from "./GraficaLineal.vue";
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  name: "KoioteGraficoLineal",
  props: {
    myProps: Object,
  },
  components: {
    GraficaLineal,
  },
  data() {
    return {
      ciclo: [],
      labels: [],
      temp_datos: [],
      initial_time: "",
      id_programa: 6,
      programa: [],
      programa_initial_time: "",
      labels_programa: [],
      teorico: [],
      loading: false,
    //   loading: true,

    };
  },

  methods: {
    ...mapActions("api", ["getCiclo", "getPrograma"]),

    sumarTiempo(h, min) {
      let tiempoTotal = h * 60 + min;
      //Sumamos el tiempo en milisegundos y guardamos en formato date para que el grafico lo pinte adecuadamente
      let salida = new Date(
        this.programa_initial_time + tiempoTotal * 60 * 1000
      );
      //Guardamos en milisegundos para hacer la suma para las siguientes
      this.programa_initial_time = new Date(salida).getTime();
      return salida;
    },
    async getCicloInicial(id) {
      const data = await this.getCiclo(id);
      this.initial_time = new Date(data.data[0].date_time).getTime();

      data.data.forEach((element) => {
        this.ciclo.push(element);
        this.labels.push(element.date_time);
        //Guardamos temperatura real
        this.temp_datos.push(Math.round(element.temp));
      });
    },
  },
  computed: {
    ...mapState("solar", ["potenciaHoy"]),
    estado_horno() {
      this.ibhLinkReadEstadoHorno();
    },
    num_programa() {
      let programa = this.ibhLinkReadPrograma();
      if (Object.keys(programa).length > 0 && programa.numPrograma != null) {
        return programa.numPrograma; //mirar si se llama asi la variable
      }
    },
    datos_programa() {
      let programa = this.ibhLinkReadPrograma();
      if (Object.keys(programa).length > 0 && programa.Puntos.length > 0) {
        return programa.Puntos; //mirar si se llama asi la variable
      }
    },
    temperatura() {
      // la temperatura actual del horno
      return this.ibhLinkReadTemperatura();
    },
    producidoARed(){
        let datos = []
        if(Object.keys(this.potenciaHoy).length > 0 && this.potenciaHoy.producido_sobrante.length > 0 ){
            datos = this.potenciaHoy.producido_sobrante.map((obj) => {
                return {
                    real:obj.value,
                    x: obj.date, // Assigning 'date' property to 'x'
                    y: obj.value, // Assigning 'value' property to 'y'
                };
            });
        }
        return datos 
    },
    producidoACasa(){
        let datos = []
        if(Object.keys(this.potenciaHoy).length > 0 && this.potenciaHoy.producido_casa.length > 0 ){
            datos = this.potenciaHoy.producido_casa.map((obj) => {
                return {
                    real:obj.value,
                    x: obj.date, // Assigning 'date' property to 'x'
                    y: obj.value, // Assigning 'value' property to 'y'
                };
            });
        }
        return datos 
    },
    consumidoDeCasa(){
        let datos = []
        if(Object.keys(this.potenciaHoy).length > 0 && this.potenciaHoy.consumido_casa.length > 0 ){
            datos = this.potenciaHoy.consumido_casa.map((obj) => {
                return {
                    real:obj.value,
                    x: obj.date, // Assigning 'date' property to 'x'
                    y: obj.value, // Assigning 'value' property to 'y'
                };
            });
        }
        return datos 
    },
    consumidoDeRed(){
        let datos = []
        if(Object.keys(this.potenciaHoy).length > 0 && this.potenciaHoy.consumido_red.length > 0 ){
            datos = this.potenciaHoy.consumido_red.map((obj) => {
                return {
                    real:obj.value,
                    x: obj.date, // Assigning 'date' property to 'x'
                    y: obj.value, // Assigning 'value' property to 'y'
                };
            });
        }
        return datos 
    },
    totalProducido(){
        let resultado = []
        if ((this.producidoARed.length && this.producidoACasa.length) && (this.producidoARed.length == this.producidoACasa.length) ) {
            resultado = this.producidoARed.map((a, index) => {
                return {
                    x: a.x,
                    real:a.y,
                    y: (a.y != undefined && this.producidoACasa[index].y != undefined)?  (a.y + this.producidoACasa[index].y):undefined,
                };
                });
        }
        return resultado
    },
    totalConsumido(){
        let resultado = []
        if ((this.consumidoDeRed.length && this.consumidoDeCasa.length) && (this.consumidoDeRed.length == this.consumidoDeCasa.length) ) {
            resultado = this.consumidoDeRed.map((a, index) => {
                return {
                    x: a.x,
                    real:a.y, 
                    y: (a.y != undefined && this.consumidoDeCasa[index].y != undefined)?  (a.y + this.consumidoDeCasa[index].y):undefined,

                };
                });
        }
        return resultado
    },
    data() {
      let datos = {
        // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August' ],
        // labels: this.labels,
        datasets: [
          {
            // lo producido que va a la red
            label: "A la red",
            borderColor: "#58d3de",
            backgroundColor: "#58d3de",
            fill: true,
            order: 2,
            data: this.totalProducido,
            pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
          },
          {
            // lo producido que va a casa
            label: "A casa",
            borderColor: "#3fe993",
            backgroundColor: "#3fe993",
            fill: true,
            order: 1,
            data: this.producidoACasa,
            pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
          },
          {
            // lo consumido de la red
            label: "De la red",
            borderColor: "#fcb979",
            backgroundColor: "#fcb979",
            fill: true,
            order: 4,
            data: this.totalConsumido.map(a => {
                return {
                    x: a.x,
                    real:a.real,
                    y: -a.y
                };
            }),
            pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
          },
          {
            // lo producido que va a la red
            label: "De solar",
            borderColor: "#95bfff",
            backgroundColor: "#95bfff",
            fill: true,
            order: 3,
            data: this.consumidoDeCasa.map(a => {
                return {
                    x: a.x,
                    real:a.real,
                    y: -a.y
                };
            }),
            pointRadius: 0, //No pintar los puntos (para que solo se vea la linea)
          },
        ],
      };
      return datos;
    },
  },
};
</script>

<style>
</style>