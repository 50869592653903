import Vue from 'vue'
import Vuex from 'vuex'

import Swal from 'sweetalert2'
import mqtt from 'mqtt/dist/mqtt'

import api from '@/store/modules/api'
import solar from '@/store/modules/solar'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    component_selected: {},
    component_selected_original: {},
    list_components: [],
    tool: {},
    full_height: null,
    pages: [],
    selected_page: {},
    selected_page_original: {},
    last_selected_page: {},
    visited_pages: [],
    selected_page_draggables: [],
    modified_draggables: [],
    modified_pages: [],
    is_play_mode: false,
    is_admin: false,
    rol: null,
    visited_pages: [],
    screen_updated: false,
    mouseX: 0,
    editing: false,
    mouseY: 0,
    miRect: {},
    show_navigation: false,
    changes: [],
    revert_changes: [],
    undoing: false,
    connection: {
      clean: true, // Reserved session
      connectTimeout: 4000, // Time out
      reconnectPeriod: 4000, // Reconnection interval
    },
    client: {
      connected: false,
    },
    subscription: {
      topic: '',
      qos: 0,
    },

  },
  getters: {
    vuexLastId(state) {
      let res = -1;

      state.list_components.forEach(element => {
        if (element.id > res) res = element.id
      });
      return res
    },
    getSelectedPage(state) {
      return state.selected_page
    },
    getLastSelectedPage(state) {
      return state.last_selected_page
    },
    getPages(state) {
      return state.pages
    },
    getIsPlayMode(state) {
      return state.is_play_mode
    },
    getScreenUpdated(state) {
      return state.screen_updated
    },
    getIsAdmin(state) {
      return state.is_admin
      // return JSON.parse(localStorage.getItem("user")).role_name == "admin"
    },
    getRol(state) {
      return state.rol
    },
    getMainPage(state) {
      return state.pages.find(a => a['is_main_page'] == true)
    }
  },
  mutations: {
    commitSetComponentSelected(state, payload) {
      state.component_selected = payload;
      state.component_selected_original = JSON.parse(JSON.stringify(payload));
    },
    commitUnsetSelectedComponent(state) {
      state.component_selected = {}
      state.component_selected_original = JSON.parse(JSON.stringify({}));
    },
    commitSetComponents(state, payload) {
      state.list_components = payload
    },
    setTool(state, payload) {
      state.tool = payload
    },
    setHeight(state, h) {
      state.full_height = h
    },
    setPages(state, pages) {
      state.pages = pages
    },
    setEditing(state, v) {
      state.editing = v
    },
    sortArray(state, objectArray) {
      objectArray.sort((a, b) => {
        return a.id - b.id;
      });
    },

    setSelectedPage(state, page) {
      state.selected_page = page
      //Si la página que estamos visitando es distinta a la última visitada, agregar a lista de visitadas
      if (state.visited_pages.length > 0) {
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        if (lastElement.id != page.id) state.visited_pages.push(page)
      } else {
        state.visited_pages.push(page)
      }
      state.selected_page_draggables = []
      state.list_components.forEach(element => {
        if (element.id_page == page.id) {
          state.selected_page_draggables.push(element)
        }
      });
      state.selected_page_original = JSON.parse(JSON.stringify(page))
    },
    addChange(state, payload) {
      state.changes.push(payload)
    },
    addRevertChange(state, payload) {
      state.revert_changes.push(payload)
    },
    unselectedPage(state) {
      state.last_selected_page = []
      state.selected_page_original = JSON.parse(JSON.stringify({}))

      state.last_selected_page = JSON.parse(JSON.stringify(state.selected_page))

      state.selected_page = {}
      state.component_selected = {}
      state.selected_page_draggables = []
    },
    addPageToArray(state, page) {
      state.pages.push(page)
    },
    addToModifiedPages(state, page) {
      state.modified_pages.push(page)
    },
    setImageToPage(state, img) {
      state.selected_page.image = img;
    },
    addToModifiedDraggables(state) {
      state.modified_draggables.push(state.component_selected)
    },
    clearModifiedDraggables(state) {
      state.modified_draggables = []
      state.changes = []
      state.revert_changes = []
    },
    clearModifiedPages(state) {
      state.modified_pages = []
    },
    clearArray(state) {
      state.list_components = []
    },
    addToDraggablesData(state, draggableData) {
      state.list_components.push(draggableData)
      if (!state.selected_page_draggables.includes(draggableData)) {
        state.selected_page_draggables.push(draggableData)
      }
    },
    setIsPlayMode(state, is_play_mode) {
      state.is_play_mode = is_play_mode
    },
    setScreenUpdated(state, value) {
      state.screen_updated = value
    },
    setIsAdmin(state, value) {
      state.is_admin = value
    },
    setRol(state, value) {
      switch (value) {
        case 1:
          state.rol = 'admin'
          break;
        case 2:
          state.rol = 'avanzado'
          break;
        case 3:
          state.rol = 'normal'
          break;
        default:
          state.rol = 'normal'
          break;
      }
    },
    setNavigation(state, value) {
      state.show_navigation = value
    },
    updateListComponents({ state, commit }, payload) {
      for (let key in payload.old) {
        if (payload.old.hasOwnProperty(key) && payload.new.hasOwnProperty(key)) { // si los dos objetos tienen la propiedad pero ha cambiado, o se ha añadido
          if (payload.old[key] !== payload.new[key]) {
            payload.old[key] = payload.new[key]
          }
        } else if (payload.old.hasOwnProperty(key) && !payload.new.hasOwnProperty(key)) {// si se ha borrado una propiedad
          payload.old[key] = null

        }
      }

    }

  },
  actions: {
    changeHeight({ commit }, h) {
      commit('setHeight', h)
    },
    changeEditing({ commit }, value) {
      commit('setEditing', value)
    },
    setShowNavigation({ commit }, value) {
      commit('setNavigation', value)
    },
    //Cuando se seleccione un elemento colocado en pantalla, se deben mostrar sus propiedades
    setComponentSelected({ commit, state }, id) {
      if (id == -1) {
        state.list_components.map(a => a.selected = false)
        commit('commitSetComponentSelected', {})
        return
      }
      //Deseleccionar todos los draggables que no sean el seleccionado
      let others = state.list_components.filter(e => e.id != id)
      others.map(a => a.selected = false)
      // state.component_selected.selected = true
      //Seleccionar el draggable seleccionado
      let payload = state.list_components.find(e => e.id == id);
      payload.selected = true
      commit('commitSetComponentSelected', payload)
    },
    deleteComponentSelected({ commit, state }) {
      state.list_components.map(a => a.selected = false)
      commit('commitUnsetSelectedComponent')
    },

    //Cargar elementos guardados
    setComponents({ commit }, payload) {
      commit('commitSetComponents', payload)
    },
    async addPageDraggables({ commit, state }, page_id) {
      //Obtener del back dragables de cada página
      let draggables = await this.dispatch('api/getPageDraggables', page_id)
      if (draggables.data.length > 0) {
        draggables = draggables.data
        for (let i = 0; i < draggables.length; i++) {
          if (draggables[i].id_page === page_id) {
            draggables[i].selected = false
            commit('addToDraggablesData', draggables[i])
          }
        }
      } else {
      }
    },

    mousemove({ commit, state }, event) {
      if (localStorage.getItem('user') != null) {
        try {
          state.miRect = document.getElementById("screen").getBoundingClientRect()
          state.mouseX = -state.miRect.x + event.clientX;
          state.mouseY = -state.miRect.y + event.clientY;
        } catch (error) {
          // console.log('Cannot get mouse position',error)
        }
      }
    },
    sortDraggables({ commit, state }) {
      commit('sortArray', state.list_components)
    },
    //Pulsa sobre herramienta
    setTool({ commit }, payload) {
      commit('setTool', payload)
    },

    //    PAGINAS
    setPages({ commit, state }, pages) {
      commit('setPages', pages)
      commit('sortArray', state.pages)
    },
    setSelectedPage({ commit, state }, page) {
      commit('unselectedPage')
      commit('setSelectedPage', page)
    },

    unselectPage({ commit }) {
      commit('unselectedPage')
    },

    async addPage({ commit }, page) {
      const res = await this.dispatch('api/postPage', page)
      page.id = res.data.id
      commit('addPageToArray', page)
      this.dispatch('setSelectedPage', page)
    },
    addModifiedPage({ commit, state }, page) {
      if (state.changes.length < 20) {
        commit('addChange', state.selected_page_original)
        // actualizamso el componente seleccionado original con el ultimo cambio realizado
        state.selected_page_original = JSON.parse(JSON.stringify(page));
      }
      let isModified = state.modified_pages.find(e => e.id == page.id)
      if (!isModified) {
        commit('addToModifiedPages', page)
      }
    },
    setImageToSelectedPage({ commit }, payload) {
      commit('setImageToPage', payload);
    },
    goToBackPage({ commit, state }) {
      //Quitar último elemento del array de páginas visitadas
      if (state.visited_pages.length > 0) {
        state.visited_pages.pop()
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        this.dispatch('setSelectedPage', lastElement)

      }
      //Coger último elemento (tras haber eliminado el anterior) y hacer set de esa página
    },
    undo({ commit, state }) {
      if (state.changes.length > 0) {
        state.undoing = true
        // si es un draggable
        if (typeof state.changes[state.changes.length - 1].id_page == "number") {
          let index = state.list_components.findIndex(e => e.id == state.changes[state.changes.length - 1].id)
          if (!index) { // si se ha borrado el elemento se volverá a crear,esta sin hacer
            console.log("no esta en la lista")
            return;
          }
          commit('addRevertChange', JSON.parse(JSON.stringify(state.list_components[index])))
          commit('updateListComponents', { old: state.list_components[index], new: state.changes[state.changes.length - 1] })
          this.dispatch('setComponentSelected', state.list_components[index].id)
        }
        // si es una pagina
        else {
          this.dispatch('manuallyChangePage', state.changes[state.changes.length - 1])
        }
        state.changes.pop()
      } else {
      }
    },
    redo({ commit, state }) {
      if (state.revert_changes.length > 0) {
        state.undoing = true
        commit('addChange', state.revert_changes[state.revert_changes.length - 1])
        // si es un draggable
        if (typeof state.revert_changes[state.revert_changes.length - 1].id_page == "number") {
          let index = state.list_components.findIndex(e => e.id == state.revert_changes[state.revert_changes.length - 1].id)
          if (!index) { // si se ha borrado el elemento se volverá a crear,esta sin hacer
            console.log("no esta en la lista")
            return;
          }
          commit('addChange', JSON.parse(JSON.stringify(state.list_components[index])))
          commit('updateListComponents', { old: state.list_components[index], new: state.revert_changes[state.revert_changes.length - 1] })
          this.dispatch('setComponentSelected', state.list_components[index].id)

          // this.dispatch('manuallyChangeDraggable',state.revert_changes[state.revert_changes.length-1])
        }
        // si es una pagina
        else {
          this.dispatch('manuallyChangePage', state.changes[state.changes.length - 1])
        }
        state.revert_changes.pop()
      }
    },
    /********************************************************************DRAGGABLES****************************************************** */

    addModifiedDraggable({ commit, state }) {
      if (!state.undoing) {
        // añadimos el ultimo cambio a la lista de cambios realizados si hay menos de 20 cambios, es un limite de ejemplo para que no sea infinito
        if (state.changes.length < 20) {
          commit('addChange', state.component_selected_original)
          // actualizamso el componente seleccionado original con el ultimo cambio realizado
          state.component_selected_original = JSON.parse(JSON.stringify(state.component_selected));
        }
        let isModified = state.modified_draggables.find(e => e.id == state.component_selected.id)
        if (!isModified) {
          commit('addToModifiedDraggables')
        }
        // console.log("LOS CAMBIOS: ",state.changes);
      }
      state.undoing = false
    },
    pushToModified({ state }, item) {
      let isModified = state.modified_draggables.find(e => e.id == item.id)
      if (!isModified) {
        state.modified_draggables.push(item)
      }
    },
    deleteModifiedDraggables({ commit }) {
      commit('clearModifiedDraggables')
    },
    deleteModifiedPages({ commit }) {
      commit('clearModifiedPages')
    },
    async saveDataInDB({ commit, state }) {
      // this.loading = true
      state.modified_draggables.forEach(async element => {
        let payload = {
          id: element.id,
          id_page: element.id_page,
          component_props: element.component_props,
          angle: element.angle,
          x: element.x,                        // hemen %etara konbertsioa egin behar da
          y: element.y,
          w: element.w,
          h: element.h,
          z: element.z
        }
        await this.dispatch('api/putDraggable', payload)
          .catch(e => {
            if (e.response.status == 413) {
              Swal.fire({
                icon: 'error',
                title: 'Imagen demasiado pesada',
                text: 'La imagen que quiere utilizar es demasiado pesada.',
              })
              return
            }
          })
      })
      commit('clearModifiedDraggables')
      state.modified_pages.forEach(async element => {
        await this.dispatch('api/putPageName', element)

      });
      commit('clearModifiedPages')
    },

    async deleteDraggable({ commit, state }) {
      if (state.component_selected) {
        await this.dispatch('api/deleteDraggable', state.component_selected.id)
        state.list_components = state.list_components.filter(e => e.id != state.component_selected.id)
        state.component_selected = {}

      }
    },

    //Modificar valores del componente
    updateAnyComponent({ commit, state }, payload) {
      let element = state.list_components.find(e => e.id = payload.id_draggable)
      element.component_props = payload
      if (!state.modified_draggables.find(e => e.id == payload.id_draggable))
        state.modified_draggables.push(element)
    },
    manuallyChangeDraggable({ commit, state }, payload) {
      let index = state.list_components.findIndex(e => e.id == payload.id)
      if (!index) { // si se ha borrado el elemento se volverá a crear,esta sin hacer
        console.log("no esta en la lista")
        return;
      }
      // console.log(state.list_components[index])
      // state.list_components[index].x = payload.x
      commit('addRevertChange', JSON.parse(JSON.stringify(state.list_components[index])))
      commit('updateListComponents', { old: state.list_components[index], new: payload })
    },
    updateComponent({ commit, state }, payload) {
      if (!payload.hasOwnProperty("id_draggable")) {
        return;
      }

      //Si el objeto no ha cambiado no actualizar lista de elementos
      // if(!_.isEqual(state.component_selected.component_props, payload)){
      state.component_selected.component_props = payload
      state.list_components.splice();
      // }

      this.dispatch('addModifiedDraggable')
    },
    clearDraggablesData({ commit }) {
      commit('unselectedPage')
      commit('clearArray')
    },
    goToBackPage({ commit, state }) {
      //Quitar último elemento del array de páginas visitadas
      if (state.visited_pages.length > 0) {
        state.visited_pages.pop()
        let lastElement = state.visited_pages[state.visited_pages.length - 1];
        this.dispatch('setSelectedPage', lastElement)

      }
      //Coger último elemento (tras haber eliminado el anterior) y hacer set de esa página
    },
    callDynamicFunction({ commit, state }, payload) {
      this.dispatch(payload.hover_function, payload.hover_function_payload)
    },
    editCanvasBorder({ commit, state }, payload) { // pasa un objeto con el valor que va a asignar a cada elemento que se corresponda con el id dell array de ids
      payload.id_array.forEach(element => {
        let canvas = state.list_components.find(e => e.id == parseInt(element))
        canvas.component_props.customizables.border = payload.value
        // canvas.component_props.customizables.border = !canvas.component_props.customizables.border
      });
    },

    async startApp({state}) {
      this.dispatch('clearDraggablesData')
      let res = await this.dispatch('api/getPages')
      if (res && res.data) {
        let pages = res.data
        //Para cada página cargamos su imagen de fondo
        pages.forEach(async element => {
          await this.dispatch('addPageDraggables', element.id)
        });
        //Guardamos en state las páginas
        this.dispatch('setPages', pages)
        
        let plantas_raw = await this.dispatch('api/getPlantas')
        this.dispatch('solar/setPlantas', plantas_raw.data)
        
        let datos = await this.dispatch('api/getSolaredgeData')
        // this.dispatch('solar/setDatosSolaredge', [{id_planta: 1, datos: datos.data}])
        this.dispatch('solar/setDatosSolaredge', datos.data)

        this.dispatch('createConnection')

      } else {
        console.log("no hay paginas");
      }
    },
    //Modificación de ancho, alto o posición de un componente
    updatePositionOrSize({ commit, state }, payload) {
      let a = state.list_components.find(e => e.id == payload.id_draggable)
      a.angle = payload.current_rect.angle
      a.x = payload.current_rect.x
      a.y = payload.current_rect.y
      a.h = payload.current_rect.h
      a.w = payload.current_rect.w
    },

    setIsPlayMode({ commit }, payload) {
      commit('setIsPlayMode', payload)
      if(payload == true){
        this.dispatch('solar/setModoDeReproduccion', 'Automática')
      }else{
        this.dispatch('solar/setModoDeReproduccion', 'Manual')
      }
    },
    setRol({ commit }, payload) {
      commit('setRol', payload)
    },
    setScreenUpdated({ commit }, payload) {
      commit('setScreenUpdated', payload)
    },
    setIsAdmin({ commit }, payload) {
      commit('setIsAdmin', payload)
    },
    setSelectedComponentsNewPosition({ commit, state }, payload) {
      state.component_selected[payload.propiedad] = parseFloat(payload.valor);
    },
    goToNextPage({commit,state}){
      if(state.selected_page.next_page_id){
        let pagina = state.pages.find(e => e.id == state.selected_page.next_page_id)
        this.dispatch('setSelectedPage', pagina)
      }else{
        console.log("La siguiente página no está definida");
      }
    },


    ///////////////////////////////////////////////// mosquitto ////////////////////////////////////////////////////////

    createConnection({ state }) {
      const { ...options } = state.connection
      const connectUrl = `${process.env.VUE_APP_MQTT_PROTOCOL}://${process.env.VUE_APP_BACK_HOST}:${process.env.VUE_APP_MQTT_PORT}`
      try {
        state.client = mqtt.connect(connectUrl, options)
      } catch (error) {
        console.log('mqtt.connect error', error)
      }

      state.client.on('connect', () => {
        console.log('Connection succeeded!')
        this.dispatch('doSubscribe', 'actualizacion_datos')

        state.client.on('message', async (topic, message) => {
          const mensaje = JSON.parse(message)
          if (topic == "actualizacion_datos") {
            console.log("MENSAJE: ",mensaje);
            // this.dispatch('solar/setDatosSolaredge', [{id_planta: 1, datos: datos}])
            this.dispatch('solar/setDatosSolaredge', mensaje.plantas)
            

          }
        })
      })
      state.client.on('error', error => {
        console.log('Connection failed', error)
      })
    },
    doSubscribe({ state }, topic) {
      const { qos } = state.subscription
      state.client.subscribe(topic, { qos }, (error, res) => {

        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
      })
    },




  },
  modules: {
    api, solar
  }
})
