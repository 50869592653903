<template >
  <div>
    <v-checkbox
    class="ml-2 mb-2"
    label="Subrayar otro al pasar ratón"
    color="primary"
    v-model="addHoverFunction"
    @change="cambioHover"
    hide-details
    ></v-checkbox>
    <v-select v-if="component_selected.component_props.customizables.hover_function=='editCanvasBorder'"
      class="ml-3 mr-3 mb-3"
      v-model="component_selected.component_props.customizables.hover_function_payload.id_array"
      :items="list_components.filter(e => e.id_page == component_selected.id_page)"
      :menu-props="{ maxHeight: '400' }"
      multiple
      item-text="id"
      hint="Selecciona los componentes a subrayar"
      persistent-hint
    ></v-select>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      addHoverFunction: false,
    };
  },
  methods:{
    cambioHover(){
      console.log("Lo he cambiado");
      if(this.addHoverFunction){
        this.component_selected.component_props.customizables.hover_function='editCanvasBorder'
      }else{
        this.component_selected.component_props.customizables.hover_function=null
      }
    }
  },
  mounted(){
    if(Object.keys(this.component_selected).length > 0 && this.component_selected.component_props.customizables.hover_function != undefined){
        this.addHoverFunction = true          
      }
  },
  computed:{
    ...mapState(['component_selected','list_components'])
  },
  watch:{
    component_selected(){
      if(Object.keys(this.component_selected).length > 0 && this.component_selected.component_props.customizables.hover_function != undefined){
        this.addHoverFunction = true
      }
    },
    'component_selected.component_props.customizables.hover_function':function(){
      this.addHoverFunction = this.component_selected.component_props.customizables.hover_function != undefined
      // this.cambioHover()
    }
  }
}
</script>
<style lang="">
  
</style>